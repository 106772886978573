
export default {
  props: {
    checkActiveClass:{},
    checkActiveIconClass:{},
    checked: {
      default: false,
    },
  },
};
