import Swal from "sweetalert2";
export default (
  { req, res, route, app, store, $axios, $cookies, redirect, env },
  inject
) => {
  const hyperAlert = async ({
    time = 0,
    icon = "info",
    iconHtml,
    important = false,
    title,
    html="",
    text = "",
    confirmButtonText = "Συνέχεια",
    cancelButtonText = "Ακύρωση",
    confirmButtonClass,
    cancelShow = false,
    input,
    inputLabel,
    inputPlaceholder,
    footer,
    callback,
  }) => {
    let toast = true;
    let position = "top-end";
    let showConfirmButton = false;
    let showCancelButton = false;
    if (!time) {
      toast = false;
      position = "center";
      showConfirmButton = true;
      showCancelButton = cancelShow;
    }
    // if(icon == 'success'){
    //   this.okSoundy();
    // }
    // else if(icon == 'error'){
    //   this.errorSoundy();
    // }
    if (callback) {
      cancelShow = true;
    }
    if (!iconHtml) {
      if (icon == "warning") {
        iconHtml =
          store.getters.store.eshop.icons.toastWarning ||
          store.getters.store.eshop.icons.warning;
      } else if (icon == "success") {
        iconHtml =
          store.getters.store.eshop.icons.toastSuccess ||
          store.getters.store.eshop.icons.check;
      } else if (icon == "error") {
        iconHtml =
          store.getters.store.eshop.icons.toastError ||
          store.getters.store.eshop.icons.close;
      } else if (icon == "info") {
        iconHtml =
          store.getters.store.eshop.icons.toastInfo ||
          store.getters.store.eshop.icons.question;
      }
    }
    if (!text && html) {
      html = `<div class="text-left pl-3 py-4">${html}</div>`;
    }else{
      html = text;
    }
    let r = await Swal.mixin({
      toast,
      position,
      allowOutsideClick: !important,
      showConfirmButton,
      showCancelButton,
      confirmButtonText,
      cancelButtonText,
      timer: time,
      timerProgressBar: true,

      onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire({
      icon,
      iconHtml: iconHtml
        ? `<img class="object-contain w-12 h-12" src="${iconHtml}"/>`
        : null,
      title,
      html,
      input,
      inputLabel,
      inputPlaceholder,
      footer,
      customClass: {
        container: "brand-popup-container",
        popup: "brand-popup",
        header: "brand-popup-header",
        title: "brand-popup-title",
        closeButton: "...",
        icon: "brand-popup-icon",
        image: "brand-popup-image",
        htmlContainer: "...",
        input: "...",
        inputLabel: "...",
        validationMessage: "...",
        actions: "brand-popup-actions",
        confirmButton: `brand-popup-confirm ${confirmButtonClass}`,
        denyButton: "brand-popup-deny",
        cancelButton: "brand-popup-cancel",
        loader: "...",
        footer: "brand-popup-footer",
        timerProgressBar: "....",
      },
    });
    if (callback) {
      callback(r.value);
    } else {
      return r;
    }
  };
  const formatAddress = (_) => {
    if (!_) {
      return "";
    }
    return `${_.address || ""} ${_.address_no || ""}, ${_.town || ""} ${_.postal_code || ""}`;
  };
  const getUserOrders = (store, proc, key, full) => {
    if (proc == "pending") {
      try {
        let order = store.getters.user.orders.filter(
          (o) => o.step < 3 && o.step != -1
        )[0];
        if (full) {
          return order;
        }
        if (key) {
          if (key == "id") {
            return order.fid || order.id;
          }
          return order[key];
        }
        return (
          order.status ||
          store.getters.store.eshop.labels[
            `step${(order.step + "").replace("-", "m")}`
          ]
        );
      } catch (error) {
        return false;
      }
    }
    return store.getters.user.orders;
  };
  const getOrderStatus = (store, order) => {
    return store.getters.store.eshop.labels[
      `step${(order.step + "").replace("-", "m")}`
    ];
  };

  const copy = (_, message) => {
    try{
      navigator.clipboard.writeText(_);
      hyperAlert({
        time: 4000,
        title: "Αντιγραφή",
        icon: "success",
        html: message || _,
      });
    }catch(e){
      console.error(e);
    }
    
  };
  const sendMail = async (name, phone, message, email, title, to,skip) => {
    let contact = store.getters.contact;
    let html = `
    <div style="text-align:left;font-size:16px;">
    Ο πελάτης με Email <strong>${email}</strong> ${
      phone ? `<br><br> Τηλέφωνο <strong>${phone}</strong>` : ""
    } ${
      name ? `<br><br> Όνομα <strong>${name}</strong>` : ""
    }<br><br> Μήνυμα <strong>${message}</strong>`;
    if (contact.item) {
      html += `<br><br> Barcode:<strong>${contact.item.barcode}</strong><br><br> Περιγραφή:<strong>${contact.item.description}</strong>`;
    }
    html += `</div>`;
    let t =
      to ||
      (contact.to ? contact.to.split(",")[0] : store.getters.store.eshop.email);
    let body = {
      bcc: contact.to && contact.to.replace(to, "").replace(",", ""),
      store_id: store.getters.store.store_id,
      to: t,
      from: store.getters.store.eshop.email,
      subject: `Φόρμα επικοινωνίας ${store.getters.store.eshop.url}`,
      title: title || contact.title,
      cta_link: `${store.getters.store.eshop.url}/item/${
        (contact.item || {}).barcode
      }`,
      anon: true,
      cta_text: `Προεπισκόπηση`,
      html,
    };
    let temp = await $axios.$post(`/mailer/prepareMail`, body);
    if (temp == "done" && !skip) {
      store.commit("contact", { item: false, open: false, title: "" });
      hyperAlert({
        time: 4000,
        title: "Το μήνυμά σας δρομολογήθηκε!",
        icon: "success",
      });
    }
  };
  const getWindow = (_) => {
    if (process.browser) {
      return window;
    } else {
      return {};
    }
  };
  inject("clerk", {
    formatAddress,
    copy,
    hyperAlert,
    getUserOrders,
    getOrderStatus,
    sendMail,
    getWindow,
  });
};
