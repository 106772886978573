export default (
  { req, res, route, app, store, $axios, redirect, env },
  inject
) => {
  const get = async (_) => {
    if (!_.title) {
      return;
    }
    try {
      let limit = "";
      if (_.limit) {
        limit = `&limit=${_.limit}`;
      }
      let property = "";
      if (_.property) {
        if(_.post){
          property = `${decodeURIComponent(_.property)}`;
        }else{
          property = `&property=${encodeURIComponent(_.property)}`;
        }
      }
      let reversed = "";
      if (_.reversed) {
        reversed = `&reversed=true`;
      }
      let q = "";
      if (_.q) {
        if(_.post){
          q = `${decodeURIComponent(_.q)}`;
        }else{
          q = `&q=${encodeURIComponent(_.q)}`;
        }
      }
      let url = `${process.env.axiosUrl}/cms/${encodeURIComponent(
        _.title
      )}?store_id=${
        _.store_id || store.getters.store._id
      }&clean=true${limit}${(q)}${property}${reversed}`;
      if(_.post){
        return { url, method: "POST",title:_.title,body:{store_id:_.store_id,key:_.title,title:_.title,q,property,limit:_.limit,clean:true,reversed:_.reversed},proc: "cms" }
      }
      if (_.urlOnly) {
        return { url, method: "get",title:_.title,proc: "cms" }
      }
      let data = await $axios.$get(url);
      store.commit("cms", { key: _.title, data:data?.docs });
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  const post = async (_)=>{
    try {
      let url = `${process.env.axiosUrl}/cms?store_id=${
        _.store_id || store.getters.store._id
      }`;
      let data = await $axios.$post(url,{title:_.title,data:_,store_id:_.store_id || store.getters.store._id,user_id:_.user_id || (store.getters.user||{})._id||null,type:'object'});
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  inject("cms", {
    get,
    post
  });
};
