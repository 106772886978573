
import userMixins from "~/plugins/userMixins";
export default {
  mixins: [userMixins],
  props: ["full", "noTitle", "noCoupon", "noNewsletter"],
  data() {
    return {
      showPassword: false,
      validations: {},
    };
  },
  mounted(){
    this.$nuxt.$on('clearRegister',_=>{
      this.register = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: "",
        newsletter: false,
        accept: false,
        coupon: "",
        GDPR: "",
      };
    })
  },
  methods: {
  },
};
