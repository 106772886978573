import { render, staticRenderFns } from "./contactForm.vue?vue&type=template&id=5f4203bc"
import script from "./contactForm.vue?vue&type=script&lang=js"
export * from "./contactForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Product: require('/root/containers/hermes_eshop/components/core/product.vue').default})
