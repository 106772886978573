import Vue from "vue";

export default (
  { req, res, route, app, store, $axios, redirect, env },
  inject
) => {
  let gauth={};
  const login = async (data) => {
    try {
      let res = await $nuxt.$axios.$post(`users/oauth-login`, data);
      if (res.token) {
        localStorage.setItem("token", res.token);
        $nuxt.$cookies.set("token", res.token);
        setTimeout(() => {location.reload();},800)
      }
    } catch (e) {
      console.error(e);
    }
  };
  const googleInit =async (client_id) => {
    //TODO https://gist.github.com/srestraj/c61d0a025f53ab7f99bd875eace5cc84
    let gg = store.state.store.plugins.find((p) => p.title == "Google Auth");
    let g_id = false;
    try {
      g_id = gg.options[0].value;
    } catch (error) {}
    if(client_id){
      return g_id;
    }
    if (google) {
      let i = await google.accounts.id.initialize({
        client_id: g_id,
        callback: prepareGoogleOneTap, //method to run after user clicks the Google sign in button
        context: "signin",
      });
      gauth.inited = true;
    }
  };
  const googleLogin = async () => {
    const tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: googleInit(true),
      scope: "profile email",
      fetch_basic_profile: true,
      prompt: "select_account", // '' | 'none' | 'consent' | 'select_account'
      callback: function (r) {
        console.info(r);
      } // your function to handle the response after login. 'access_token' will be returned as property on the response
    });
    if (!$nuxt.$gAuth) {
      return;
    }
    return;
    const googleUser = await $nuxt.$gAuth.signIn();
    // googleUser.getId() : Get the user's unique ID string.
    // googleUser.getBasicProfile() : Get the user's basic profile information.
    // googleUser.getAuthResponse() : Get the response object from the user's auth session. access_token and so on
    let temp = await googleUser.getBasicProfile();
    let data = {
      proc: "google",
      store_id: $nuxt.$store.getters.store.id,
      avatar: temp.getImageUrl(),
      first_name: temp.getGivenName(),
      last_name: temp.getFamilyName(),
      email: temp.getEmail(),
      id: googleUser.getId(),
      token: (await googleUser.getAuthResponse()).id_token,
    };
    login(data);
  };
  const googleOneTap = async () => {
    await googleInit();
    google.accounts.id.prompt();
  };
  const prepareGoogleOneTap = (d) => {
    console.info(d);
    if (d.credential) {
      login({
        ...d,
        proc: "google",
        jwt: true,
        store_id: store.getters.store.id,
      });
    }
  };
  const facebookInit = async () => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    return new Promise((res) => {
      let fbpl = store.getters.store.plugins.find(
        (p) => p.title == "Facebook Auth"
      );
      if (fbpl) {
        window.fbAsyncInit = function () {
          FB.init({
            appId: (fbpl.options || [])[0].value,
            cookie: true,
            xfbml: true,
            version: "v14.0",
          });

          FB.AppEvents.logPageView();
          res(FB);
        };
      }
    });
  };
  const facebookLogin = async () => {
    if (window.FB === undefined) {
      await facebookInit();
    }
    FB.login(
      async (res) => {
        if (res.status === "connected" && res.authResponse) {
          FB.api("/me?fields=id,email,first_name,last_name,picture", (ress) => {
            // The email should be in the res, assuming the user has one registered with FB.
            if (ress.email) {
              let data = {
                proc: "facebook",
                store_id: $nuxt.$store.getters.store.id,
                avatar: ((ress.picture || {}).data || {}).url,
                first_name: ress.first_name,
                last_name: ress.last_name,
                email: ress.email,
                id: ress.id,
                token: res.authResponse.accessToken,
              };
              login(data);
            }
          });
        }
      },
      {
        scope: "email",
        authType: "rerequest",
      }
    );
  };
  const facebookLoggedIn = async () => {
    if (window.FB === undefined) {
      await facebookInit();
    }
    FB.getLoginStatus(function (response) {});
  };
  const googleFinger = async (proc, id) => {
    if (proc == "register") {
      const publicKeyCredentialCreationOptions = {
        challenge: Uint8Array.from("notsorandom", (c) => c.charCodeAt(0)),
        rp: {
          name: "test1",
        },
        user: {
          id: Uint8Array.from("UZSL85T9AFC", (c) => c.charCodeAt(0)),
          name: "lee@webauthn.guide",
          displayName: "Lee",
        },
        pubKeyCredParams: [{ alg: -7, type: "public-key" }],
        authenticatorSelection: {
          authenticatorAttachment: "cross-platform",
        },
        timeout: 60000,
        attestation: "direct",
      };

      const credential = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions,
      });
    } else if (proc == "login") {
      const publicKeyCredentialRequestOptions = {
        challenge: Uint8Array.from("notsorandom", (c) => c.charCodeAt(0)),
        allowCredentials: [
          {
            id: Uint8Array.from(id, (c) => c.charCodeAt(0)),
            type: "public-key",
            transports: ["usb", "nfc", "ble", "hybrid", "internal"],
          },
        ],
        timeout: 60000,
      };

      const assertion = await navigator.credentials.get({
        publicKey: publicKeyCredentialRequestOptions,
      });
    }
  };

  inject("Oauth", {
    googleInit,
    googleLogin,
    googleOneTap,
    prepareGoogleOneTap,
    facebookInit,
    facebookLogin,
    facebookLoggedIn,
    googleFinger,
    gauth
  });
  setTimeout(() => {
    $nuxt.$on("facebookLogin", (_) => {
      facebookLogin();
    });
    $nuxt.$on("googleLogin", (_) => {
      // googleLogin();
      googleOneTap();
    });
    $nuxt.$on("googleOneTap", (_) => {
      googleOneTap();
    });
  }, 1);
};
