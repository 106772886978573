import { render, staticRenderFns } from "./register.vue?vue&type=template&id=6bb970f4"
import script from "./register.vue?vue&type=script&lang=js"
export * from "./register.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Field: require('/root/containers/hermes_eshop/components/core/field.vue').default,Check: require('/root/containers/hermes_eshop/components/hyper/p295/check.vue').default})
