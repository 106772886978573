
import { Fragment } from "vue-frag";
import LazyHydrate from "vue-lazy-hydration";
import LazyComponent from "v-lazy-component/vue2";
export default {
  props: ["element"],
  name: "elementBuilder",
  components: {
    Fragment,
    LazyHydrate,
    LazyComponent,
  },
  data() {
    return {
      elementLocal: JSON.parse(JSON.stringify(this.element || {})),
      maxWidth: 3000,
      close: false,
      destroy: false,
      isOpen: false,
      isDDHead: false,
      isDDBody: false,
      timer: [],
    };
  },
  async beforeMount() {},
  created() {
    if (!(this.elementLocal.active || []).includes("slider")) {
      this.$hyperElement.active(this.elementLocal);
    } else {
      if (process.browser) {
        setTimeout(() => {
          let parent = document.querySelector(
            `[data-shopster_id="${
              (this.elementLocal.active || "")
                .replace("slider", "")
                .split(":")[0]
            }"]`
          );
          if (parent) {
            this.elementLocal.isActive =
              (parent.dataset || {}).activeslide ==
              parseInt(this.elementLocal.active.split(":")[1]);
            this.$forceUpdate();
          }
        }, 100);
      }
    }
    if (process.browser) {
      if (this.elementLocal.type == "coreDropdown") {
        this.$hyperElement.dropDownInit(this.elementLocal);
      }
      this.$hyperElement.elementEventPool(this, this.elementLocal);
      if (
        (this.elementLocal.classes || []).includes("dragster") ||
        this.elementLocal.draggable
      ) {
        let slider = document.querySelector(
          `.e${this.elementLocal.uid} .subcontainer`
        );
        if (slider) {
          let mouseDown = false;
          let startX, scrollLeft;

          let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
          };
          let stopDragging = function (event) {
            mouseDown = false;
          };

          slider.addEventListener("mousemove", (e) => {
            e.preventDefault();
            if (!mouseDown) {
              return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
          });
          // Add the event listeners
          slider.addEventListener("mousedown", startDragging, false);
          slider.addEventListener("mouseup", stopDragging, false);
          slider.addEventListener("mouseleave", stopDragging, false);
        }
      }
    }
  },
  async mounted() {
    //if (this.elementLocal.watchKey) {
    //  this.$watch(this.elementLocal.watchKey, function (n, o) {}, {
    //    deep: true,
   //   });
    //}

    if (process.browser) {
      window.addEventListener("resize", () => {
        this.maxWidth = this.$hyperElement.setMaxWidth();
      });
      if (this.elementLocal.actions?.length) {
        let target = await new Promise((res) => {
          let counter = 0;
          let timer = setInterval(() => {
            counter++;
            let targetEl = document.querySelector(
              `[data-shopster_id="${this.elementLocal.shopster_id}"]`
            );
            if (targetEl || counter > 5) {
              clearInterval(timer);
              res(targetEl);
            }
          }, 80);
        });
        this.$hyperElement.actions(
          target ||
            document.querySelector(`[data-uid="${this.elementLocal.uid}"]`),
          this.elementLocal,
          this
        );
      }
      if (this.elementLocal.shopster_id) {
        this.$nuxt.$on(`e${this.elementLocal.shopster_id}`, (_) => {
          this.elementLocal.styleImportant = {
            ...this.elementLocal.styleImportant,
            ..._,
          };
          this.$forceUpdate();
        });
      }
    }
    let active = this.elementLocal.active;
    if ((active + "").includes("slider")) {
      this.$nuxt.$on(`${active.split(":")[0]}_update`, (_) => {
        this.elementLocal.isActive = parseInt(active.split(":")[1]) == _;
        this.$forceUpdate();
      });
    }
    if (this.elementLocal.type == "coreMegaSlider") {
      this.elementLocal.slides = Array.from(
        { length: this.elementLocal.slides - 1 + 1 },
        (_, index) => 1 + index
      )
        .map(Number)
        .filter((s) => this.elementLocal.elements.some((e) => e.slide == s));

      this.elementLocal.activeSlide = this.elementLocal.slides[0] || 1;
      this.$nuxt.$on(`slider${this.elementLocal.shopster_id}`, (_) => {
        this.elementLocal.activeSlide = _;
        if (!this.elementLocal.noSlider) {
          this.$refs.slider.swiper.slideTo(_ - 1);
        } else {
          this.$nuxt.$emit(
            `slider${this.elementLocal.shopster_id}_update`,
            this.elementLocal.activeSlide
          );
        }
      });
    }
    let self = this;
    if (this.elementLocal.localBind) {
      if (this.elementLocal.localBind == "cart.items") {
        if (!this.elementLocal.data) {
          this.elementLocal.data = this.$store.getters.cart.items;
          this.$forceUpdate();
        }
        this.$nuxt.$on("updateCart", (_) => {
          this.elementLocal.data = this.$store.getters.cart.items;
          if (this.elementLocal.slider && this.elementLocal.swiper) {
            this.elementLocal.swiper.update();
          }
        });
      } else if (this.elementLocal.localBind == "history.items") {
        this.elementLocal.data = this.$store.getters.history.items;
        this.$forceUpdate();
        this.$nuxt.$watch(
          "$store.getters.history.items",
          function (n, o) {
            if (self.elementLocal) {
              self.elementLocal.data = n;
              if (self.elementLocal.slider && self.elementLocal.swiper) {
                self.elementLocal.swiper.update();
              }
            }
          },
          { deep: true }
        );
      } else if (this.elementLocal.localBind == "user.actualFavorites") {
        try {
          if ((this.$store.getters.user || {})._id) {
            let favs = await this.$hyperUser.getFavorites(
              this.$store.getters.user
            );
            this.$store.commit("user", favs);
          }
        } catch (error) {}
        this.elementLocal.data = this.$store.getters.user.actualFavorites;
        this.$forceUpdate();
        this.$nuxt.$watch(
          "$store.getters.user.actualFavorites",
          function (n, o) {
            if (self.elementLocal) {
              self.elementLocal.data = n;
              if (self.elementLocal.slider && self.elementLocal.swiper) {
                self.elementLocal.swiper.update();
              }
            }
          },
          { deep: true }
        );
      }
    }
    // if (process.browser) {
    //   console.log('================================');
    //   console.log(this.elementLocal.type);
    //   for (let i = 0; i < this.timer.length - 1; i++) {
    //     console.log(this.timer[i].label);
    //     console.log(this.timer[i + 1].time - this.timer[i].time);
    //   }
    //   console.log(this.timer[this.timer.length - 1].time-this.timer[0].time);
    //   console.log('//================================');
    // }
  },
  methods: {
    hover(over) {
      if (!(this.elementLocal.actions || []).length) {
        return;
      }
      if (over) {
        this.elementLocal.hover = true;
      } else {
        this.elementLocal.hover = false;
      }
      this.$forceUpdate();
    },
    importantReactiveStyle(e) {
      let pseudo = "";
      if (this.$store.getters.isMobile) {
        pseudo = "Mobile";
      }
      if (e.hover) {
        pseudo += "Hover";
      }

      if (!e.rendered) {
        e.rendered = {};
      }
      let rendered = {};
      Object.keys(e.styleImportant || {}).forEach((k) => {
        rendered[k] = e.styleImportant[k];
      });
      e.rendered[pseudo] = rendered;
      return rendered;
    },
  },
  computed: {},
};
