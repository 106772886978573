
export default {
  data(){
    return{
      position:'top',
      positionV:''
    }
  },
  mounted(){
    if(process.browser && localStorage.getItem('positionPreview')){
      this.position = localStorage.getItem('positionPreview');
    }
    if(process.browser && localStorage.getItem('positionPreviewV')){
      this.positionV = localStorage.getItem('positionPreviewV');
    }
  },
  methods:{
    lsSet(p){
      this.position = p;
      localStorage.setItem('positionPreview',p)
    },
    lsSetV(p){
      this.positionV = p;
      localStorage.setItem('positionPreviewV',p)
    },
    reload(){
      if(process.browser){
        location.reload();  
      }
    }
  }
}
