import Vue from "vue";
import {VTooltip, VPopover} from "v-tooltip";
import LazyHydrate from 'vue-lazy-hydration';
// import VueLuxon from "vue-luxon";
import VueImgFallback from './fallback-directive';
Vue.directive('img-fallback',VueImgFallback);
import swiper from './swiper'
Vue.directive('swiper',swiper);
// Vue.use(VueLuxon, {
//   output: {
//     zone: "Europe/Athens",
//     locale: "el-GR",
//   },
// });
Vue.directive('tooltip', VTooltip)
Vue.component('v-popover', VPopover)
Vue.component('LazyHydrate',LazyHydrate)

