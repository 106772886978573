
export default {
  name: "status",
  head() {
    return {
      title: "Status",
      link: [
        {
          hid: "icon",
          rel: "icon",
          type: "image/x-icon",
          href: this.$store.getters.store.eshop.favicon,
        },
      ],
    };
  },
  data() {
    return {};
  },
};
