import { render, staticRenderFns } from "./full_dynamic.vue?vue&type=template&id=5f2fb1f6"
import script from "./full_dynamic.vue?vue&type=script&lang=js"
export * from "./full_dynamic.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementBuilder: require('/root/containers/hermes_eshop/components/core/elementBuilder.vue').default})
