export default function check(store_id, route,app, preview, timestamp,build_no,hijack = '') {
  let links=[]
  if (
    links.every(
      (l) => l.hid != `elementsfooter,mobile,navBar,header,index,addtocart`
    )
  ) {
    links.push({
      href: `/ecss/${store_id}/footer,mobile,navBar,header,index,addtocart,aux,${hijack}.css?${preview}&v=${timestamp}&build_no=${build_no}`,
      rel: "stylesheet",
      hid: `elementsfooter,mobile,navBar,header,index,addtocart,aux`,
      type:"text/css",
    });
  }
  // let skip = ['index','track','']
  if (route.name != "index") {
    let name = route.path.replace("/f/", "").replace("/featured/", "");
    if (route.name == "item-id") {
      name = "item";
    } else if (route.name == "cart-step") {
      name = "cart";
    } else if (route.name == "success") {
      name = "success";
    } else if (route.name == "search-q" || (route.name||[]).includes("catalog-")) {
      name = "search";
    } else if (route.name == "account-tab") {
      name = "account";
    }
    name = name.replace(/\//g,'')
    if (name) {
      if (links.every((l) => l.hid != `elements${name}`)) {
        links.push({
          href: `/ecss/${store_id}/${name}.css?${preview}&v=${timestamp}&build_no=${build_no}`,
          rel: "stylesheet",
          hid: `elements${name}`,
          type:"text/css"
        });
      }
    }
  }
  return links;
}
