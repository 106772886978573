
export default {
  props: ["open"],
  data() {
    return {
      tab: "products",
      active: "",
      active2: "",
      needs: [],
      brands: [],
    };
  },
  async mounted() {
    let cmsBrands = this.$store.getters.cms.brands;
    if(!cmsBrands || !cmsBrands.length) {
      cmsBrands = await this.$cms.get({ title: "brands", limit: 400 });
    }
    this.brands = cmsBrands;
    let cmsNeeds = this.$store.getters.cms.needs;
    if(!cmsNeeds || !cmsNeeds.length) {
      cmsNeeds = await this.$cms.get({ title: "needs", limit: 400 });
    }
    this.needs = cmsNeeds;
  }
};
