import doys from "@/static/doys.js";
import Vue from "vue";
export default {
  data() {
    return {
      icons: {
        "-1": "ic-close",
        0: "ic-package",
        1: "ic-package",
        2: "ic-delivery",
        3: "ic-check-mark-circle",
      },
      manual: false,
      reviews: [],
      suggestions: [],
      doys,
      showPassword: false,
      tab: "userInfo",
      userTab:'info',
      dataTab:'password',
      isLogged: false,
      login: {
        email: "",
        password: "",
      },
      register: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
        phone: "",
      },
      user: {
        first_name: "",
        last_name: "",
        invoice: {},
      },
      newAddress: {
        address: "",
        town: "",
        postal_code: "",
        level: "",
      },
      invoice: {
        vatid: "",
        doy: "",
        doy_num: "",
        company: "",
        activity: "",
      },
      password: "",
      password_confirm: "",
      orders: [],
      forgotPassword: false,
      forgotPasswordEmail: "",
      proc: "login",
      orderLock: false,
      elements: this.$store.getters.store.eshop.elements.filter(
        (e) =>
          e.page == this.$store.getters.store.eshop.layout.account ||
          e.page == "account"
      ),
    };
  },
  watch: {
    "$route.name": function (v) {
      if (!((this.$store.getters.user||{}).orders || []).length && v.includes("account")) {
        this.$hyperUser.getOrders();
      }
    },
  },
  async mounted() {
    this.$nuxt.$on(`address_autocomplete_user`, (_) => {
      this.newAddress.address = '';
      this.newAddress.address_no = '';
      this.newAddress.town = '';
      this.newAddress.postal_code = '';
      this.newAddress.floor = '';
      let valid = true;
      if(_.address){
        this.$hyperUtility.vSet(this.newAddress, "address", _.address);
      }else{
        valid=false;
      }
      if(_.address_no){
        this.$hyperUtility.vSet(this.newAddress, "address_no", _.address_no);
      }else{
        valid=false;
      }
      if(_.postal_code){
        this.$hyperUtility.vSet(this.newAddress, "postal_code", _.postal_code);
      }else{
        valid=false;
      }
      if(_.town){
        this.$hyperUtility.vSet(this.newAddress, "town", _.town);
      }else{
        valid=false;
      }
      if(_.country){
        this.$hyperUtility.vSet(this.newAddress, "country", _.country);
      }else{
        valid=false;
      }
      if(_.lat){
        this.$hyperUtility.vSet(this.newAddress, "lat", _.lat);
      }
      if(_.lng){
        this.$hyperUtility.vSet(this.newAddress, "lng", _.lng);
      }
      if(!valid){
        this.manual = true;
        this.$emit('validate_user');
      }
    });
    this.$nuxt.$on('openManual',_=>{
      this.manual = true;
    })
    if (this.$route.params.tab) {
      this.tab = this.$route.params.tab;
    }
    if (this.$store.getters.user) {
      // await this.$hyperUser.getOrders();
      this.user = this.$store.getters.user;
    }
    this.$hyperUtility.paintSvg(
      "login",
      "#login",
      "#320A64",
      this.$store.getters.store.eshop.secondary
    );
    this.$hyperUtility.paintSvg(
      "signup",
      "#signup",
      "#320A64",
      this.$store.getters.store.eshop.secondary
    );
  },
  methods: {
    async replaceOrder(o) {
      let self = this;
      this.$clerk.hyperAlert({
        title: "Τοποθέτηση παραγγελίας;",
        confirmButtonText: "Τοπόθετηση",
        html: `<div class="text-sm"><p>Θα προστεθούν τα προϊόντα της παραγγελίας στο καλάθι, θα οριστούν οι τρόποι αποστολής και πληρωμής που ίσχυουν σε αυτήν την παραγγελία.</p><br><p> Σε περίπτωση που κάποιο από τα μέσα δεν υποστηρίζονται πλέον δεν θα επιλεγεί.</p><br><p class="bg-primaryAlpha01 rounded-hypert px-3 py-2">Θα μεταφερθείτε στο καλάθι για να ολοκληρώσετε την παραγγελία.</p></div>`,
        callback: (v) => {
          if (!v) {
            return;
          }

          let address = {};
          let payment = {};
          let shippingExists = self.$store.getters.store.shippings.find(
            (s) => s._id == o.shipping._id
          );
          let paymentExists = self.$store.getters.store.payments.find(
            (s) => s._id == o.shipping._id
          );
          if (shippingExists) {
            address = shippingExists;
            address.address = o.shipping.address;
          }
          if (paymentExists) {
            payment = paymentExists;
          }
          self.$store.commit("cart", {
            ...self.$store.getters.cart,
            shipping:address,
            payment,
          });

          self.$hyperCart.addFromBarcodes(
            ((o.items || []).map((i) => i.barcode) || []).join(","),
            0,
            true
          );
          setTimeout(() => {
            self.$router.push(`/cart/shipping`);
          }, 1000);
        },
      });
    },
    async getReviews() {
      let res = await this.$reviews.user(this.user._id);
      this.reviews = res.docs.filter(r=>r.item_id);
      this.$forceUpdate();
    },
    async getSuggestions() {
      let res = await this.$reviews.suggestions(
        (this.user || {})._id,
        (this.user || {}).email
      );
      this.suggestions = res;
    },
    exportData() {
      let self = this;
      this.$clerk.hyperAlert({
        icon: "warning",
        title: "Εξαγωγή δεδομένων",
        html: '<p class="text-left">Επιθυμείτε να σας στείλουμε τα προσωπικά σας δεδομένα στην ηλεκτρονική διεύθυνση του λογαριασμού σας; Εισάγετε τον κωδικό σας στο πεδίου που ακολουθεί και πατήστε "Θέλω"</p>',
        confirmButtonText: "Θέλω",
        cancelButtonText: "Ακύρωση",
        input: "password",
        inputPlaceholder: "Εισαγωγή κωδικού",
        callback: async (v) => {
          if (v) {
            try {
              let res = await self.$axios.$post(`/users/export-data`, {
                store_id: this.$store.getters.store.id,
                email: self.user.email,
                password: v,
              });
              if (res.success) {
                self.$clerk.hyperAlert({
                  title: "Επιτυχία",
                  icon: "success",
                  html: "Τα στοιχεία στάλθηκαν στην διεύθυνση email σας.",
                  cancelShow: false,
                });
              }
            } catch (error) {}
          }
        },
      });
    },
    cancelDeleteData() {
      let self = this;
      this.$clerk.hyperAlert({
        icon: "warning",
        title: "Ακύρωση διαγραφής δεδομένων",
        html: '<p class="text-left">Επιθυμείτε την ακύρωση της διαδικασίας διαγραφής του λογαριασμού σας;</p>',
        confirmButtonClass: "success",
        confirmButtonText: "Ακύρωση",
        cancelButtonText: "Ακύρωση",
        input: "password",
        inputPlaceholder: "Εισαγωγή κωδικού",
        callback: async (v) => {
          if (v) {
            try {
              let res = await self.$axios.$post(`/users/cancel-delete-data`, {
                email: self.user.email,
                password: v,
              });
              if (res.success) {
                self.$clerk.hyperAlert({
                  title: "Επιτυχία",
                  icon: "success",
                  html: "Ακυρώθηκε η διαδικασία διαγραφής",
                  cancelShow: false,
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
        },
      });
    },
    deleteData() {
      let self = this;
      this.$clerk.hyperAlert({
        icon: "warning",
        title: "ΔΙΑΓΡΑΦΗ δεδομένων",
        html: '<p class="text-left">Επιθυμείτε να διαγράψουμε οριστικά τον λογαριασμό σας;Η διαγραφή θα πραγματοποιηθεί σε ~7 ημέρες. Η κίνηση είναι ΜΗ ΑΝΑΣΤΡΕΨΙΜΗ. Το ιστορικό των παραγγελιών, των αγαπημένων προϊόντων και όλων των στοιχείων της πορείας σας θα χαθεί. Εαν επιθυμείτε την ΟΡΙΣΤΙΚΗ διαγραφή του λογαριασμού σας, εισάγετε τον κωδικό σας στο πεδίου που ακολουθεί και πατήστε "Διαγραφή"</p>',
        confirmButtonClass: "failed",
        confirmButtonText: "Διαγραφή",
        cancelButtonText: "Ακύρωση",
        input: "password",
        inputPlaceholder: "Εισαγωγή κωδικού",
        callback: async (v) => {
          if (v) {
            try {
              let res = await self.$axios.$post(`/users/delete-data`, {
                email: self.user.email,
                password: v,
              });
              if (res.success) {
                self.$clerk.hyperAlert({
                  title: "Επιτυχία",
                  icon: "success",
                  html: "Τα στοιχεία έχουν μπει σε διαδικασία διαγραφής. Σας ευχαριστούμε για την προτίμηση σας, θα χαρούμε να σας ξανα δούμε στο μέλλον.",
                  cancelShow: false,
                  callback: () => {
                    // self.$hyperUser.logout();
                  },
                });
              }
            } catch (error) {
              console.error(error);
            }
          }
        },
      });
    },
    toggleInspect(o) {
      o.inspect = !o.inspect;
      this.$forceUpdate();
    },
    setDefaultAddress(index){
      this.user.addresses = [this.user.addresses[index],...this.user.addresses.filter((a,ai)=>ai!=index)];
      this.$hyperUser.saveUser(this.user);
    },
    deleteAddress(index) {
      let self = this;
      this.$clerk.hyperAlert({
        confirmButtonText: "Διαγραφή",
        cancelShow: true,
        title: "Διαγραφή διεύθυνσης;",
        callback: async (v) => {
          if (v) {
            self.user.addresses = self.user.addresses.filter(
              (a, ind) => ind != index
            );
            self.$hyperUser.saveUser(self.user);
          }
        },
      });
    },
    addAddress() {
      this.user.addresses.push(JSON.parse(JSON.stringify(this.newAddress)));
      this.newAddress = {
        address: "",
        town: "",
        postal_code: "",
        level: "",
      };
      this.$hyperUser.saveUser(this.user);
    },
  },
};
