import { onLoad } from "../helpers/onLoad";

export default (ctx, inject) => {
  async function loadAsyncJs() {
    (
      (ctx.store.getters.store.eshop.injections||[]).filter(
        (i) =>
          i.ssr &&
          (i.url == "*"
            ? true
            : i.url == "/"
            ? ctx.route.fullPath == "/"
            : ctx.route.fullPath.endsWith(i.url))
      ) || []
    ).forEach((inj) => {
      if ((inj.multiple || []).length) {
        inj.multiple.forEach((i, index) => {
          if (
            !i.tag
          ) {
            return;
          }
          var head = document.getElementsByTagName("head")[0];
          var resource = document.createElement(i.tag);
          if (i.attrHref) {
            resource.setAttribute("href", i.attrHref);
          }
          if (i.attrSrc) {
            resource.setAttribute("src", i.attrSrc);
          }
          if (i.tag == "script") {
            if (i.innerHtml) {
              let exists = document.querySelector(
                `[shopster-injector="${inj.name + i.tag + index}"]`
              )
              // if(exists){
              //   exists.remove();
              // }
              resource.innerHTML = `
              var deps = "${i.depedencies}";
              var inner = ${JSON.stringify(i.innerHtml)};
              var timer${inj.name + i.tag + index} = setInterval(function(){
                if(((deps || "").split(",") || []).every(
                  (d) => window[d]
                )){
                  clearInterval(timer${inj.name + i.tag + index});
              try{setTimeout(_=>eval(inner),30);}catch(e){console.warn(e)}}}, 30);`;
            }
          }
          if (i.tag == "meta") {
            resource.setAttribute("name", i.attrName);
            resource.setAttribute("content", i.attrContent);
          }
          if (i.tag == "link") {
            resource.setAttribute("rel", "stylesheet");
          }
          head.appendChild(resource);
        });
      }
    });
  }

  if (process.browser) {
    // loadAsyncJs();
    ctx.app.router.afterEach((to, _from) => {
      loadAsyncJs();
    });
  }
  inject("shopsterJsLoader", { loadAsyncJs });
};
