import { onLoad } from "../helpers/onLoad";

export default (ctx, inject) => {
  if (process.browser) {
    window.initMaps = () => {
      ctx.store.commit('readyness',{key:'googleMapsAutocomplete',value:true});
    };
  }
  onLoad(() => {
    try {
      if ((ctx.store.getters.store.flat_plugins["Google Maps"] || {}).id) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${
          (ctx.store.getters.store.flat_plugins["Google Maps"] || {}).id
        }&libraries=places&callback=initMaps`;
        setTimeout(_=>document.head.appendChild(script),100)
      }
    } catch (error) {
      console.error(error);
    }
  });
};
