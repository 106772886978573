import Vue from "vue";
import GmapVue from "~/node_modules/gmap-vue";

export default (
  { req, res, route, app, store, $axios, redirect, env },
  inject
) => {
  let gm = ((store.getters.store||{}).plugins||[]).find((p) => p.title == "Google Maps");
  Vue.use(GmapVue, {
    load: {
      key: (((gm||{}).options || [])[0]||{}).value,
      region: "GR",
      language: "el",
      
      libraries: "places", // This is required if you use the Autocomplete plugin
      // OR: libraries: 'places,drawing'
      // OR: libraries: 'places,drawing,visualization'
      // (as you require)

      //// If you want to set the version, you can do so:
      // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
  });
};
