
export default {
  props: ["open"],
  data() {
    return {
      tab: "products",
      brands: [],
      needs: [],
      active: "",
      active2: "",
    };
  },
  async mounted() {
    let cmsBrands = this.$store.getters.cms.brands;
    if (!cmsBrands) {
      cmsBrands = await this.$cms.get({ title: "brands", limit: 400 });
    }
    this.brands = cmsBrands;
    let cmsNeeds = this.$store.getters.cms.needs;
    if (!cmsNeeds) {
      cmsNeeds = await this.$cms.get({ title: "needs", limit: 400 });
    }
    this.needs = cmsNeeds;
  },
  methods: {
    async recalc(index, index2) {
      setTimeout(() => {
        try {
          let mobileMenu = document.querySelector("#mobileMenuSlider");
          if (mobileMenu) {
            mobileMenu.swiper.updateAutoHeight(500);
          }
        } catch (e) {
          console.error(e);
        }
      }, 1000);
      if (!isNaN(index) && !this.active) {
        return;
      }
      if (!isNaN(index2) && !this.active2) {
        return;
      }
      await new Promise((res) => setTimeout(res, 500));
      if (!isNaN(index2)) {
        this.$hyperUtility.scrollHeight(
          `.menu_panel_${index}_${index2}`,
          "maxHeight"
        );
        await new Promise((res) => setTimeout(res, 500));
      }
      this.$hyperUtility.scrollHeight(`.menu_panel_${index}`, "maxHeight");
    },
  },
};
