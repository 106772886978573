
export default {
  name: "footer_dynamic",
  data() {
    return {
      year: "",
      elements: this.$store.getters.store.eshop.elements.filter(
        (e) => e.page == this.$store.getters.store.eshop.layout.footer  || e.page == 'footer'
      ),
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
};
