
import product from "./product";
export default {
  components: {
    product,
  },
  name: "contactForm",
  data() {
    return {
      email: "",
      phone: "",
      name: "",
      message: "",
    };
  },
  watch: {
    "$store.getters.contact.open": function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  methods: {
    //send message to shop email+
    async send() {
      let contact = this.$store.getters.contact;
      let html = `
      <div style="text-align:left;font-size:16px;">
      Ο πελάτης με Email <strong>${this.email}</strong> ${
        this.phone ? `<br><br> Τηλέφωνο <strong>${this.phone}</strong>` : ""
      } ${
        this.name ? `<br><br> Όνομα <strong>${this.name}</strong>` : ""
      }<br><br> Μήνυμα <strong>${this.message}</strong>`;
      if (contact.item) {
        html += `<br><br> Barcode:<strong>${contact.item.barcode}</strong><br><br> Περιγραφή:<strong>${contact.item.description}</strong>`;
      }
      html += `</div>`;
      let to = contact.to?contact.to.split(',')[0]:this.$store.getters.store.eshop.email;
      let body = {
        bcc:(contact.to||'').replace(to,'').replace(',',''),
        store_id: this.$store.getters.store.store_id,
        to,
        from: this.$store.getters.store.eshop.email,
        subject: `Φόρμα επικοινωνίας ${this.$store.getters.store.eshop.url}`,
        title: contact.title,
        cta_link: (contact.item||{}).barcode?`${this.$store.getters.store.eshop.url}/item/${contact.item.barcode}`:`http://${this.$store.getters.store.eshop.url}`,
        anon: true,
        cta_text: `Προεπισκόπηση`,
        html,
      }
      let temp = await this.$axios.$post(`/mailer/prepareMail`, body);
      if (temp == "done") {
        this.$store.commit("contact", { item: false, open: false, title: "" });
        this.$clerk.hyperAlert({
          time: 4000,
          title: "Το μήνυμά σας δρομολογήθηκε!",
          icon: "success",
        });
      }
    },
  },
};
