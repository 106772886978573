
export default {
    props:['url','type','tag'],
    name:'hyperLink',
    methods: {
    linkProps (path) {
      if(this.type == 'coreText'){
        return{
          is:this.tag
        }
      }else if(!path && this.type != 'coreButton'){
        return{
          is:'div'
        }
      }else if(!path){
        return{
          is:'a',href:'javascript:;'
        }
      }else if (path.match(/^(http(s)?|ftp):\/\//) || path.target === '_blank') {
        return {
          is: 'a',
          href: path,
          target: '_blank',
          rel: 'noopener'
        }
      }
      else if(path.match(/^(mailto|tel):/)){
        return {
          is: 'a',
          href: path,
          target: '_blank',
          rel: 'noopener'
        }
      }
      
      return {
        is: 'nuxt-link',
        to: path
      }
    }
  }
};
