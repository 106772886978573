
export default {
  name: "cookies",
  data() {
    return {
      show: false,
      details: false,
      facebook: false,
      google: false,
      skroutz: false,
      choices: [],
      runOnce: false,
    };
  },
  mounted() {
    this.choices =
      (this.$store.getters.store.eshop.cookies || {}).choices || [];
    if (!this.choices.some((c) => c.keys.includes("cart"))) {
      this.choices.push({
        key: "cart",
        force: true,
        explain:
          "Είναι τα απαραίτητα cookies για τη λειτουργία της σελίδας, όπως η διατήρηση του καλαθιού των αγορών σας.",
        active: true,
      });
    }
    if (process.browser) {
      this.$nuxt.$emit("defaultAnalytics");
      let analytics = this.$analytics;
      let plugins = this.$store.getters.store.flat_plugins;
      if (!this.runOnce) {
        analytics.informant($nuxt);
        this.runOnce = true;
      }
      if (
        plugins &&
        plugins["Skroutz Analytics"] &&
        plugins["Skroutz Analytics"].skroutz_id
      ) {
        this.skroutz = true;
        if (window.skroutz_analytics) return;
        setTimeout(() => {
          analytics.initSkroutz(
            plugins["Skroutz Analytics"].skroutz_id,
            this.$nuxt.$router
          );
          this.$store.commit("analytics", "skroutz");
        }, 1000 * 3);
      }
    }
    if (process.browser && !localStorage.getItem("cookie-consent")) {
      this.show = true;
      return;
    }
    if (process.browser && localStorage.getItem("cookie-consent") != "false") {
      if (localStorage.getItem("cookie-consent-keys")) {
        try {
          let plugins = this.$store.getters.store.flat_plugins;
          let keys = JSON.parse(localStorage.getItem("cookie-consent-keys"));
          if (!keys.includes("Google Analytics")) {
            this.$analytics.optOut();
          }
          this.startAnalytics(keys);
          return;
        } catch (error) {
          console.error(error);
        }
      }
      this.startAnalytics();
    }
  },
  methods: {
    startAnalytics(keys) {
      if (process.browser) {
        let analytics = this.$analytics;
        let plugins = this.$store.getters.store.flat_plugins;
        if (keys) {
          plugins = Object.keys(plugins).reduce((acc, p) => {
            if (!keys.includes(p)) {
              acc[p] = plugins[p];
            }
            return acc;
          }, {});
        }
        if (!keys || keys.includes("Google Analytics")) {
          if (!this.runOnce) {
            analytics.informant($nuxt);
            this.runOnce = true;
          }
        }
        if (
          plugins &&
          plugins["Facebook Pixel"] &&
          plugins["Facebook Pixel"].facebook_id
        ) {
          this.facebook = true;
          if (window.fbq) return;
          setTimeout(() => {
            analytics.initFacebook(
              plugins["Facebook Pixel"].facebook_id,
              this.$nuxt.$router
            );
            this.$store.commit("analytics", "facebook");
          }, 1000 * 20);
        }
        if (
          plugins &&
          plugins["Google Analytics"] &&
          plugins["Google Analytics"].google_id
        ) {
          this.google = true;
          // setTimeout(() => {
          //   analytics.initGoogle(
          //     plugins["Google Analytics"].google_id,
          //     this.$nuxt.$router
          //   );
          // }, 1000 * 20);
          this.$store.commit("analytics", "google");
        }
        this.$nuxt.$emit("startAnalytics");
      }
    },
    agreeSpecific() {
      let keys = (this.choices.filter((c) => c.active) || []).flatMap((c) =>
        (c.keys + "").split(",")
      );
      localStorage.setItem("cookie-consent", Date.now());
      localStorage.setItem("cookie-consent-keys", JSON.stringify(keys));
      if (!keys.includes("Google Analytics")) {
        this.$analytics.optOut();
      }
      this.show = false;
      this.startAnalytics(keys);
    },
    agree() {
      if (process.browser) {
        localStorage.setItem("cookie-consent", Date.now());
        localStorage.setItem(
          "cookie-consent-keys",
          JSON.stringify(this.choices.flatMap((c) => (c.keys + "").split(",")))
        );
        this.show = false;
        this.startAnalytics();
      }
    },
    disagree() {
      if (process.browser) {
        localStorage.setItem("cookie-consent", "false");
        this.$analytics.optOut();
        this.show = false;
      }
    },
  },
};
