export default ({
  app,
  store,
  route
}, inject) => {
  const create = async (body) => {
    if (!body.item || !body.rating || !store.getters.user) {
      return false;
    }
    let res = await app.$axios.$post(`/reviews`, {
      item: body.item.barcode,
      item_id: body.item._id,
      description: body.item.description,
      user: store.getters.user.id,
      email: store.getters.user.email,
      text: body.text,
      rating: body.rating,
      store_id: store.getters.store.id
    }, {
      headers: {
        Authorization: `Bearer ${store.getters.token}`
      }
    });
    
    return res;
  }
  const user = async(user)=>{
    let res = await app.$axios.$get(`/reviews/store?store_id=${store.getters.store.id}&user=${user}`)
    return res;
  }
  const get = async (item) => {
    let bc = ``;
    if((item||{}).barcode){
      bc=`&item=${item.barcode}`
    }
    let res = await app.$axios.$get(`/reviews/store?store_id=${store.getters.store.id}${bc}&limit=50`)
    return res;
  }
  const bulk = async (barcodes) => {
    try {
      let res = await app.$axios.$post(`/reviews/bulk`, {
        store_id: store.getters.store.id,
        barcodes
      })
      return res;
    } catch (error) {
      console.error(error);
    }

  }
  const remove = async (r) => {
    try {
        let res = await app.$axios.delete(`/reviews/${r._id}?store_id=${store.getters.store.id}`, {
            headers: {
              Authorization: `Bearer ${store.getters.token}`
            }
          })
    } catch (error) {
        console.error(error);
    }
  }
  const suggestions = async (user,email)=>{
    if(!user || !email){
      return [];
    }
    try {
      let res = await app.$axios.$get(`/reviews/suggestions?store_id=${store.getters.store.id}&user=${user}&email=${email}`,{
        headers: {
          Authorization: `Bearer ${store.getters.token}`
        }
      })
      return res;
    } catch (error) {
      console.error(error);
    }
  }
  inject('reviews', {
    create,
    get,
    bulk,
    remove,
    user,
    suggestions
  })
}
