export default (
  {
    req,
    res,
    route,
    $loading,
    app,
    store,
    $axios,
    $cookies,
    redirect,
    env,
    $clerk,
    $hyperUtility,
  },
  inject
) => {
  const parseArticle = async (article) => {
    if (article) {
      let temp = article;
      let a = article;
      temp = await Promise.all(
        (a.html || "").split("@@@").map(async (h) => {
          let sc = h.split("@@");
          if (sc && sc[0] == "item") {
            let d = await store.dispatch("getData", {
              axios: $axios,
              e: {
                params: [
                  {
                    value: sc[2],
                    key: "barcodes",
                  },
                ],
              },
              cookies: app.$cookies,
              app,
            });
            if ((d.data || [])[0]) {
              h = `<v-popover trigger="hover" class="inline"><a href="${$hyperUtility.urlItemCreator(
                d.data[0]
              )}" target="_blank" class="tooltip-target  b3">${
                sc[1]
              }</a><template slot="popover"><product :item="${JSON.stringify(
                d.data[0]
              )
                .replace(/'/g, "\\'")
                .replace(/"/g, "'")}"></product></template></v-popover>`;
            } else {
              let nuxtlink = `<a target="_blank" href="${$hyperUtility.urlItemCreator(
                { barcode: sc[2] }
              )}">${sc[1]}</a>`;
              h = h.replace(sc.join("@@"), nuxtlink);
            }
          }
          return h;
        })
      );
      article.html = temp.join("");
      return article;
    }
  };
  const getArticles = async (
    key,
    search,
    limit,
    page,
    meta,
    adjacent = "",
    select = ""
  ) => {
    let articles = [];
    if (adjacent) {
      adjacent = `&adjacent=${adjacent}`;
    }
    if (select) {
      select = `&select=${select}`;
    }
    try {
      
      let res = await $axios.$get(
        `${process.env.axiosUrl}/blog?limit=${limit}${adjacent}${select}&page=${page || 1}&store_id=${
          store.getters.store.store_id
        }${search ? `&${key}=${encodeURIComponent(search)}` : ""}`
      );
      if (res.docs) {
        if (select.includes('html')) {
          res.docs = res.docs.map((a) => {
            a.time = a.time || Math.round((a.html || []).trim().split(" ").length / 200);
            return a;
          });
        }
        if (meta) {
          res.articles = res.docs;
          delete res.docs;
          return res;
        }
        if (adjacent) {
          return res;
        }
        articles = res.docs;
      }
      return articles;
    } catch (error) {
      console.error(error);
    }
  };
  const getCategories = async () => {
    let metadata = {};
    try {
      let res = await $axios.$get(
        `blog/categories?store_id=${store.getters.store.store_id}`
      );
      metadata = res;
    } catch (error) {}
    return metadata;
  };
  inject("hyperBlog", {
    getArticles,
    parseArticle,
    getCategories,
  });
};
