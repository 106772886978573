

export default {
  props: ["item","option","nested"],
  name: "options",
  data() {
    return {
      updateProne: false,
    };
  },
  methods: {
    select(v) {
      this.$emit("select", v);
      this.updateProne = true;
      //suck it vuejs
      this.updateProne = false;
    },
  },
};
