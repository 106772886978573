import Vue from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import greek from "greek-utils";
import VueMask from "v-mask";
import { debounce } from "lodash";
import { ObserveVisibility } from "vue-observe-visibility";

Vue.use(VueMask);
export default (
  {
    req,
    res,
    route,
    $loading,
    app,
    store,
    $axios,
    $cookies,
    redirect,
    env,
    $clerk,
  },
  inject
) => {
  const selectProduct = (item, element) => {
    if (!item) {
      return;
    }
    if (!element) {
      element = {};
    }
    if (store.getters.store.eshop.products) {
      let generic;
      let specific;
      if (!element.productMobile) {
        element.productMobile = element.product;
      }
      if (store.getters.isMobile && element.productMobile) {
        let p = (store.getters.store.eshop.products.filter(
          (p) => p.title == element.productMobile
        ) || [])[0];
        if (p) {
          return p;
        }
      } else if (!store.getters.isMobile && element.product) {
        let p = (store.getters.store.eshop.products.filter(
          (p) => p.title == element.product
        ) || [])[0];
        if (p) {
          return p;
        }
      }
      store.getters.store.eshop.products.forEach((p, i) => {
        if (p.desktopHide && !store.getters.isMobile) {
          return;
        }
        if (p.mobileHide && store.getters.isMobile) {
          return;
        }
        if (!p.assignOnly) {
          if (!p.restrict_key || p.restrict_key == "undefined") {
            generic = p;
          } else if (
            p.restrict_key == "tag" &&
            ((item.tags || []).filter((t) => t.key == p.restrict_tag)[0] || {})
              .value
          ) {
            specific = p;
          } else if (item[p.restrict_key] == p.restrict_value) {
            specific = p;
          }
        }
      });
      if (specific) {
        return specific;
      } else {
        return generic;
      }
    }
  };

  const initFeatures = (_) => {
    Vue.directive("observe-visibility", ObserveVisibility);
    Vue.filter("highlight", function (words, query) {
      words = words
        .normalize("NFD")
        .toLowerCase()
        .replace(/[\u0300-\u036f]/g, "")
        .split(" ");
      query = query
        .normalize("NFD")
        .toLowerCase()
        .replace(/[\u0300-\u036f]/g, "")
        .split(" ");
      words = words.map((w) => {
        if (query.some((q) => greek.toGreeklish(q) == greek.toGreeklish(w))) {
          w = `<span class='highlight'>${w}</span>`;
        }
        return w;
      });
      return words.join(" ");
    });

  };
  const scrollDirection = debounce((window, e) => {
    window.scrollingDirection = window.oldScroll > window.scrollY?'up':'down';
    store.commit('scrollingDirection',window.scrollingDirection)
    window.oldScroll = window.scrollY;
  }, 100);

  const isScrollingUp = () => {
    if(process.browser){
      return window.scrollingDirection == 'up'
    }
    return false;
  }
  const isScrollingDown = () => {
    if(process.browser){
      return window.scrollingDirection == 'down'
    }
    return false;
  }

  const collapsible = (selector,open,offset)=>{
    if(process.browser && document.querySelector(selector)){
      return open? document.querySelector(selector).scrollHeight+(offset||0)+'px' : 0+'px'
    }
  }
  
  vSelect.props.components.default = () => ({
    Deselect: {
      render: (createElement) =>
        createElement("img", {
          attrs: {
            src: app.$hyperUtility.eshopIcon("close", 1),
          },
          class: {
            "h-5 w-5 object-contain": true,
          },
        }),
    },
    OpenIndicator: {
      render: (createElement) =>
        createElement("span", {
          domProps: {
            innerHTML: `<img class="h-6 w-6 object-contain inline rotate-90" src="${app.$hyperUtility.eshopIcon(
              "arrow",
              1
            )}">`,
          },
        }),
    },
  });
  Vue.component("v-select", vSelect);
  inject("hyperLayout", {
    selectProduct,
    initFeatures,
    scrollDirection,
    isScrollingUp,
    isScrollingDown,
    collapsible
  });
};
