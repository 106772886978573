
export default {
  name: "product",
  props: [
    "item",
    "negative",
    "carousel",
    "compact",
    "display",
    "element",
    "product",
    "inGrid",
    "index",
  ],
  data() {
    return {};
  },
  methods: {
    handleClick(nuxt, e, item, index,Tag) {
      
      // this.analysis(nuxt, e, item, index);
      if (e.action) {
        nuxt.$hyperUtility.sEval(e.action);
      } else if (
        e.type == "cart" &&
        !(item.availability || {}).noCart &&
        !(item.availability || {}).redirect
      ) {
        nuxt.$hyperCart.addToCart(item, false, false, e.noPop, e.noAlert);
      } else if (
        e.type == "cart" &&
        (item.availability || {}).noCart &&
        e.notifyItem
      ) {
        $store.commit("contact", {
          open: true,
          item: item,
          title: `Ειδοποίησε με όταν είναι διαθέσιμο!`,
        });
      } else if (e.key == "notify") {
        $store.commit("contact", {
          to: e.to,
          item,
          title: e.title,
          open: true,
        });
      } else if (e.type == "favorite") {
        nuxt.$hyperItem.toggleFavorite(item);
        item.inFavorites = !item.inFavorites;
      }
    },
    analysis(nuxt, e, item,index) {
      if (process.browser) {
        let shopster_id;
        if (e) {
          let el = document.querySelector(`.e${e.uid}`);
          if (el) {
            shopster_id = el.closest("[data-shopser_id]");
            if (shopster_id) {
              shopster_id = shopster_id.dataset.shopser_id;
            }
          }
        }
        nuxt.$emit("informant:select_item", {
          id: shopster_id || "listing_page",
          item: item,
          index: index,
        });
      }
    },
   
  },
};
