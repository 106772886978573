export default (
  {
    req,
    res,
    route,
    $loading,
    app,
    store,
    $axios,
    $cookies,
    redirect,
    env,
    $clerk,
  },
  inject
) => {
  const title = () => {
    let t =
      store.getters.store.eshop.pageTitle || store.getters.store.eshop.name;
    if (!t.includes(store.getters.store.eshop.name)) {
      t = `${t} | ${store.getters.store.eshop.name}`;
    }
    return t;
  };

  const meta = (overrides) => {
    // {
    //   content: "width=device-width, initial-scale=1.0",
    //   name: "viewport",
    // },
    let bd = [
      {
        hid: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: store.getters.store.eshop.name,
      },
      {
        hid: "description",
        name: "description",
        content: store.getters.store.eshop.description,
      },
      {
        hid: "keywords",
        name: "keywords",
        content: store.getters.store.eshop.keywords,
      },
      {
        hid: "robots",
        name: "robots",
        content:
          (((route.params || {}).q || "").includes("page=1") ||
            !((route.params || {}).q || "").includes("page=")) &&
          !((route.query || {}).q || "")
            ? "index,follow"
            : "noindex,follow",
      },
      {
        name: "theme-color",
        content: store.getters.store.eshop.primary,
      },
    ];
    if (
      store.getters.item &&
      (route.fullPath.includes("/item/") ||
        route.fullPath.includes("/product/"))
    ) {
      let comment = false;
      if ((store.getters.item || {}).comment) {
        comment = `${(store.getters.item.comment || "")
          .replace(/<[^>]+>/g, "")
          .slice(0, 150)}...`;
      }
      overrides = {
        ...overrides,
        ...{
          description:
            (store.getters.item || {}).seo_description ||
            comment ||
            `${
              store.getters.item.description
            }, Τιμή ${app.$hyperItem.actualPrice(
              store.getters.item
            )}, Εκδότης/Κατασκευαστής: ${
              store.getters.item.manufacturer
            }, Κατηγορία: ${store.getters.item.family}, ${
              store.getters.item.subfamily
            }`,
          keywords:
            store.getters.item.seo_keywords ||
            `${store.getters.item.business_activity} ${store.getters.item.family} ${store.getters.item.subfamily} ${store.getters.item.category}`,
        },
      };
    }
    if (overrides) {
      let keys = Object.keys(overrides) || [];
      bd = bd.map((b) => {
        if (keys.includes(b.hid)) {
          b.content = overrides[b.hid];
        }
        return b;
      });
    }
    return bd;
  };

  const og = (overrides, proc, article) => {
    // let url = `https://${store.getters.store.eshop.url}${route.fullPath}`;
    let path = route.path;
    try{
      path = app.router.currentRoute.path
    }catch (e){
    }
    let url = `https://${store.getters.host}${path}`;
    let comment = store.getters.item?.description;
    if ((store.getters.item || {}).comment) {
      comment = `${(store.getters.item.comment || "")
        .replace(/<[^>]+>/g, "")
        .slice(0, 150)}...`;
    }
    let ogdescription =
      store.getters.item &&
      (route.fullPath.includes("/item/") ||
        route.fullPath.includes("/product/"))
        ? {
            hid: "og:description",
            property: "og:description",
            content: comment,
          }
        : {
            hid: "og:description",
            property: "og:description",
            content: store.getters.store.eshop.description,
          };
    let ogimage = store.getters.item && (route.fullPath.includes("/item/") ||
    route.fullPath.includes("/product/"))
      ? [
          {
            hid: "og:image",
            property: "og:image",
            content: ((store.getters.item || {}).images || [])[0],
          },
          {
            hid: "og:image:secure_url",
            property: "og:image:secure_url",
            content: ((store.getters.item || {}).images || [])[0],
          },
          {
            hid: "og:image:alt",
            property: "og:image:alt",
            content: (store.getters.item || {}).description,
          },
        ]
      : [
          {
            hid: "og:image",
            property: "og:image",
            content:
              store.getters.store.eshop.og_image ||
              store.getters.store.eshop.logo ||
              "",
          },{
            hid: "og:image:secure_url",
            property: "og:image:secure_url",
            content: 
            store.getters.store.eshop.og_image ||
            store.getters.store.eshop.logo ||
            "",
          },
          {
            hid: "og:image:alt",
            property: "og:image:alt",
            content: 'Εικόνα ιστοσελίδας',
          }
        ];
    let ogtitle =
      store.getters.item &&
      (route.fullPath.includes("/item/") ||
        route.fullPath.includes("/product/"))
        ? {
            hid: "og:title",
            property: "og:title",
            content: store.getters.item.description,
          }
        : {
            hid: "og:title",
            property: "og:title",
            content: overrides?.['og:title'] || `${store.getters?.filters?.business_activity || store.getters?.filters?.manufacturer || store.getters?.filters?.['tag.anagki']} ${store.getters.store.eshop.name}`,
          };
    let ogtype =
      store.getters.item &&
      (route.fullPath.includes("/item/") ||
        route.fullPath.includes("/product/"))
        ? {
            hid: "og:type",
            property: "og:type",
            content: "product",
          }
        : { hid: "og:type", property: "og:type", content: "website" };

    let bd = [
      {
        hid: "og:locale",
        property: "og:locale",
        content: "el_GR",
      },
      ogdescription,
      ogtype,
      ...ogimage,
      ogtitle,
      {
        hid: "og:url",
        property: "og:url",
        content: url,
      },
    ];
    if (proc == "article" && article) {
      bd.push({
        hid: "article:published_time",
        property: "article:published_time",
        content: new Date(article.created_at).toISOString(),
      });
      bd.push({
        hid: "article:modified_time",
        property: "article:modified_time",
        content: new Date(article.updated_at).toISOString(),
      });
    }
    if (overrides) {
      let keys = Object.keys(overrides) || [];
      bd = bd.map((b) => {
        if (keys.includes(b.hid)) {
          b.content = overrides[b.hid];
        }
        return b;
      });
    }
    return bd;
  };
  const ld = (proc, data, breadcrumbArr) => {
    let item;
    let article;
    if (proc == "article") {
      article = data || {};
    } else {
      item = data || { prices: {}, availability: {} };
    }

    let def = {
      hid: "ldjson",
      type: "application/ld+json",
      json: {
        "@context": "http://schema.org",
        "@type": "Organization",
        name: store.getters.store.eshop.name,
        legalName: store.getters.store.name,
        url: `https://${store.getters.store.eshop.url}`,
        logo:
          store.getters.store.eshop.og_image ||
          store.getters.store.eshop.logo ||
          "",
        address: {
          "@type": "PostalAddress",
          streetAddress: store.getters.store.eshop.address || "",
          addressLocality: store.getters.store.eshop.town || "",
          postalCode: store.getters.store.eshop.postal_code || "",
          addressCountry: store.getters.store.eshop.country || "",
        },
        potentialAction: {
          "@type": "SearchAction",
          target: `https://${store.getters.store.eshop.url}/${
            !(store.getters.store.eshop.search || []).includes("elastic")
              ? "search/+q"
              : `${store.getters.store.eshop.catalogRoute}/?q`
          }={search_term_string}`,
          "query-input": "required name=search_term_string",
        },
      },
    };
    if (store.getters.store.eshop.sameAs) {
      def.json.sameAs = store.getters.store.eshop.sameAs;
    }

    if (proc == "item") {
      let comment = item?.description;
      if ((item || {}).comment) {
        comment = `${(item.comment || "")
          .replace(/<[^>]+>/g, "")
          .slice(0, 150)}...`;
      }
      let product = {
        "@type": "Product",
        image: item.images || [],
        sku: item.sku || item.barcode,
        brand: item.manufacturer,
        name: `${item.description || "Καταργήθηκε"}`,
        description: `${comment || "Καταργήθηκε"}`,
        offers: {
          "@type": "Offer",
          availability: `http://schema.org/${
            !(item.availability || {}).noCart ? "InStock" : "SoldOut"
          }`,
          price: `${app.$hyperItem.actualPrice(item, true)}`,
          priceCurrency: "EUR",
          shippingDetails: {
            "@type": "OfferShippingDetails",
            shippingRate: {
              "@type": "MonetaryAmount",
              value: (item.shipping || {}).cost || 0,
              currency: "EUR",
            },
            shippingDestination: {
              "@type": "DefinedRegion",
              addressCountry: "GR",
            },
          },
        },
      };
      let book = {
        "@type": "Book",
        isbn: item.tags_ISBN || item.tags_isbn,
        bookFormat: "https://schema.org/Product",
        brand: item.manufacturer,
        mainEntity: {
          "@type": "Book",
          bookFormat: "https://schema.org/Book",
          datePublished: item.tags_release_date || "",
          isbn: item.tags_ISBN || item.tags_isbn,
          name: `${item.description || "Καταργήθηκε"}`,
          numberOfPages: item.tags_pages || "",
          publisher: `${item.manufacturer || "Καταργήθηκε"}`,
        },
      };
      def.json = { ...def.json, ...product };
      if (item.tags_ISBN || item.tags_isbn) {
        def.json = { ...def.json, ...book };
      }
    } else if (proc == "breadcrumb") {
      let breadcrumb = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [],
      };
      let elastic = (store.getters.store.eshop.search || []).includes(
        "elastic"
      );
      let ba =
        store.getters.filters.business_activity_slug ||
        store.getters.filters.business_activity;
      let uba = encodeURIComponent(ba);
      let fa =
        store.getters.filters.family_slug || store.getters.filters.family;
      let ufa = encodeURIComponent(fa);
      let sf =
        store.getters.filters.subfamily_slug || store.getters.filters.subfamily;
      let usf = encodeURIComponent(sf);
      let ca =
        store.getters.filters.category_slug || store.getters.filters.category;
      let uca = encodeURIComponent(ca);
      let ma =
        store.getters.filters.manufacturer_slug ||
        store.getters.filters.manufacturer;
      let uma = encodeURIComponent(ma);
      if (ma) {
        breadcrumb.itemListElement.push({
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": `https://${store.getters.store.eshop.url}/${
              !elastic
                ? `search/+manufacturer=${uma}`
                : `${store.getters.store.eshop.catalogRoute}/m.${uma}`
            }`,
            name: ma,
          },
        });
      } else if (ba || fa || sf || ca) {
        if (ba) {
          breadcrumb.itemListElement.push({
            "@type": "ListItem",
            position: 1,
            item: {
              "@id": `https://${store.getters.store.eshop.url}/${
                !elastic
                  ? `search/+business_activity=${uba}`
                  : `${store.getters.store.eshop.catalogRoute}/${uba}`
              }`,
              name: ba,
            },
          });
        }
        if (fa) {
          breadcrumb.itemListElement.push({
            "@type": "ListItem",
            position: 2,
            item: {
              "@id": `https://${store.getters.store.eshop.url}/${
                !elastic
                  ? `search/+business_activity=${uba}+family=${ufa}`
                  : `${store.getters.store.eshop.catalogRoute}/${uba}/${ufa}`
              }`,
              name: fa,
            },
          });
        }
        if (sf) {
          breadcrumb.itemListElement.push({
            "@type": "ListItem",
            position: 3,
            item: {
              "@id": `https://${store.getters.store.eshop.url}/${
                !elastic
                  ? `search/+business_activity=${uba}+family=${ufa}+subfamily=${usf}`
                  : `${store.getters.store.eshop.catalogRoute}/${uba}/${ufa}/${usf}`
              }`,
              name: sf,
            },
          });
        }
        if (ca) {
          breadcrumb.itemListElement.push({
            "@type": "ListItem",
            position: 4,
            item: {
              "@id": `https://${store.getters.store.eshop.url}/${
                !elastic
                  ? `search/+business_activity=${uba}+family=${ufa}+subfamily=${usf}+category=${uca}`
                  : `${store.getters.store.eshop.catalogRoute}/${uba}/${ufa}/${usf}/${uca}`
              }`,
              name: ca,
            },
          });
        }
      } else if (store.getters.filters["tag.anagki"]) {
        breadcrumb.itemListElement.push({
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": `https://${store.getters.store.eshop.url}/${
              !elastic
                ? `search/+tags_key=anagki+tags_value=${store.getters.filters["tag.anagki"]}`
                : `${store.getters.store.eshop.catalogRoute}/tag.anagki.${store.getters.filters["tag.anagki"]}`
            }`,
            name:
              store.getters.filterLabels[store.getters.filters["tag.anagki"]] ||
              store.getters.filters["tag.anagki"],
          },
        });
      }

      def.json = { ...def.json, ...breadcrumb };
    } else if (proc == "genericBreadcrumb") {
      let breadcrumb = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: [],
      };
      for (let i = 0; i < breadcrumbArr.length; i++) {
        breadcrumb.itemListElement.push({
          "@type": "ListItem",
          position: i + 1,
          name: breadcrumbArr[i].name,
          item: `https://${store.getters.host}${breadcrumbArr[i].name.url}`,
        });
      }
      def.json = { ...def.json, ...breadcrumb };
    } else if (proc == "article") {
      if (!article.author) {
        article.author = {};
      }
      let a = {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        headline: article.title,
        alternativeHeadline: "and the women who love them",
        image: article.headerMedia || "",
        editor: article.author.first_name + " " + article.author.last_name,
        genre: article.category,
        keywords: (article.tags || []).join(" "),
        wordcount: (article.html || "").length,
        publisher: store.getters.store.eshop.name,
        url: `https://${store.getters.store.eshop.url}/blog/article/${article.slug}`,
        datePublished: app.$luxon(article.created_at, { format: "yyyy-LL-dd" }),
        dateCreated: app.$luxon(article.created_at, { format: "yyyy-LL-dd" }),
        dateModified: app.$luxon(article.updated_at, { format: "yyyy-LL-dd" }),
        description: article.short,
        articleBody: article.html,
        author: {
          "@type": "Person",
          name: article.author.first_name + " " + article.author.last_name,
        },
      };
      def.json = { ...def.json, ...a };
    }
    return def;
  };

  const schemas = (proc, data,route) => {
    if (proc == "organization") {
      return {
        "@context": "http://schema.org",
        "@type": "Organization",
        name: store.getters.store.eshop.name,
        legalName: store.getters.store.name,
        url: `https://${store.getters.store.eshop.url}`,
        logo:
          store.getters.store.eshop.og_image ||
          store.getters.store.eshop.logo ||
          "",
        address: {
          "@type": "PostalAddress",
          streetAddress: store.getters.store.eshop.address || "",
          addressLocality: store.getters.store.eshop.town || "",
          postalCode: store.getters.store.eshop.postal_code || "",
          addressCountry: store.getters.store.eshop.country || "",
        },
        potentialAction: {
          "@type": "SearchAction",
          target: `https://${store.getters.store.eshop.url}/${
            !(store.getters.store.eshop.search || []).includes("elastic")
              ? "search/+q"
              : `${store.getters.store.eshop.catalogRoute}/?q`
          }={search_term_string}`,
          "query-input": "required name=search_term_string",
        },
      };
    }
    if (proc == "breadcrumbItem") {
      let path = route?.path || app.router.currentRoute.path;
      let keys = ["business_activity", "family", "subfamily", "category"];
      let pathRange = path
        .split("/")
        .filter((r) => r && r != "catalog" && r != "item" && r != "product");
      let d = pathRange.reduce(
        (acc, d, index) => {
          acc.push({
            "@type": "ListItem",
            position: index + 2,
            name: data[keys[index]] || data?.description,
            item: `https://${store.getters.store.eshop.url}/${
              !(store.getters.store.eshop.search || []).includes("elastic")
                ? `search/+${d}=${encodeURIComponent(data[d])}`
                : `catalog/${pathRange.slice(0, index + 1).join("/")}`
            }`,
          });
          return acc;
        },
        [
          {
            "@type": "ListItem",
            position: 1,
            name: "Αρχική",
            item: `https://${store.getters.store.eshop.url}`,
          },
        ]
      );
      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: d,
      };
    }
    if (proc == "breadcrumbFilters") {
      let keys = [
        "business_activity",
        "family",
        "subfamily",
        "category",
        "manufacturer",
        "tag.anagki",
      ];
      if(store.getters.store.eshop.importantFields){
        keys = [...keys,store.getters.store.eshop.importantFields];
      }
      keys = keys.filter((k) => data[k] && !data[k].includes("_or_"));
      let values = keys.map((k) => {
        let v = data[k];
        if (store.getters.store.eshop.slugReady) {
          v=app.$hyperUtility.slugifyGreek(v);
        }
        if (k.includes("tag")) {
          v = `${k}.${v}`;
        } else if (k == "manufacturer") {
          v = `m.${v}`;
        }
        return v;
      });
      
      let d = keys.reduce(
        (acc, d, index) => {
          acc.push({
            "@type": "ListItem",
            position: index + 2,
            name: store.getters.filterLabels[data[d]] || data[d],
            item: `https://${store.getters.store.eshop.url}/${
              !(store.getters.store.eshop.search || []).includes("elastic")
                ? `search/+${d}=${encodeURIComponent(data[d])}`
                : `catalog/${values.slice(0,index+1).join("/")}`
            }`,
          });
          return acc;
        },
        [
          {
            "@type": "ListItem",
            position: 1,
            name: "Αρχική",
            item: `https://${store.getters.store.eshop.url}`,
          },
        ]
      );
      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: d,
      };
    }
    if (proc == "breadcrumb") {
      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: data.filter(b=>b?.name).map((b, i) => ({
          "@type": "ListItem",
          position: i + 1,
          name: b.name,
          item: `https://${store.getters.host}${b.url}`,
        })),
      };
    }
    if (proc == "itemList") {
      let itemList = {
        "@context": "https://schema.org",
        "@type": "ItemList",
        itemListElement: [],
      };
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        let availability;
        if (item.availability.noCart) {
          availability = "https://schema.org/OutOfStock";
        } else if (item.stock>2) {
          availability = "https://schema.org/InStock";
        } else {
          availability = "https://schema.org/LimitedAvailability";
        } 
        let schema_item = {
          "@type": "ListItem",
          position: i + 1,
          item: {
            "@type": "Product",
            name: item.description,
            image: item.images || [],
            offers: {
              "@type": "Offer",
              availability,
              price: item.prices.price,
              priceCurrency: "EUR",
            },
            sku: item.sku || item.barcode,
            url: `https://${store.getters.store.eshop.url}${app.$hyperUtility.urlItemCreator(item)}`,
            brand: {
              "@type": "Brand",
              name: item.manufacturer,
            }
          },
        }
        if(item.rating){
          schema_item.item.aggregateRating = {
            "@type": "AggregateRating",
            ratingValue: item.rating,
            reviewCount: item.rating_count,
          }
        }
        itemList.itemListElement.push(schema_item);
      }
      return itemList;
    }
    if (proc == "item") {
      let comment = data?.description;
      if ((data || {}).comment) {
        comment = `${(data.comment || "")
          .replace(/<[^>]+>/g, "")
          .slice(0, 150)}...`;
      }
      let aggregateRating = data.rating_count>1?{aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: data.rating,
        reviewCount: data.rating_count,
      }}:{};
      return {
        "@context": "http://schema.org",
        "@type": "Product",
        image: data.images || [],
        sku: data.sku || data.barcode,
        brand: data.manufacturer,
        name: `${data.description || "Καταργήθηκε"}`,
        description: `${comment||data.description || "Καταργήθηκε"}`,
        ...aggregateRating,
        offers: {
          "@type": "Offer",
          availability: `http://schema.org/${
            !(data.availability || {}).noCart ? "InStock" : "SoldOut"
          }`,
          price: `${app.$hyperItem.actualPrice(data, true)}`,
          priceCurrency: "EUR",
          shippingDetails: {
            "@type": "OfferShippingDetails",
            shippingRate: {
              "@type": "MonetaryAmount",
              value: (data.shipping || {}).cost || 0,
              currency: "EUR",
            },
            shippingDestination: {
              "@type": "DefinedRegion",
              addressCountry: "GR",
            },
          },
        },
      };
    }
    if (proc == "book") {
      return {
        "@context": "http://schema.org",
        "@type": "Book",
        isbn: data.tags_ISBN || data.tags_isbn,
        bookFormat: "https://schema.org/Product",
        brand: data.manufacturer,
        mainEntity: {
          "@type": "Book",
          bookFormat: "https://schema.org/Book",
          datePublished: data.tags_release_date || "",
          isbn: data.tags_ISBN || data.tags_isbn,
          name: `${data.description || "Καταργήθηκε"}`,
          numberOfPages: data.tags_pages || "",
          publisher: `${data.manufacturer || "Καταργήθηκε"}`,
        },
      };
    }
    if (proc == "article") {
      return {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        headline: data.title,
        alternativeHeadline: "and the women who love them",
        image: data.headerMedia || "",
        editor: data.author.first_name + " " + data.author.last_name,
        genre: data.category,
        keywords: (data.tags || []).join(" "),
        wordcount: (data.html || "").length,
        publisher: store.getters.store.eshop.name,
        url: `https://${store.getters.store.eshop.url}/blog/article/${data.slug}`,
        datePublished: app.$luxon(data.created_at, { format: "yyyy-LL-dd" }),
        dateCreated: app.$luxon(data.created_at, { format: "yyyy-LL-dd" }),
        dateModified: app.$luxon(data.updated_at, { format: "yyyy-LL-dd" }),
        description: data.short,
        articleBody: data.html,
        author: {
          "@type": "Person",
          name: data.author.first_name + " " + data.author.last_name,
        },
      };
    }
  };
  const manifest = () => {};

  inject("hyperSeo", { title, meta, og, ld, manifest, schemas });
};
