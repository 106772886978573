import { render, staticRenderFns } from "./p295_specific.vue?vue&type=template&id=afde8e3c"
import script from "./p295_specific.vue?vue&type=script&lang=js"
export * from "./p295_specific.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {P295PickGift: require('/root/containers/hermes_eshop/components/hyper/p295/p295_pick_gift.vue').default})
