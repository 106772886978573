
export default {
  props: {
    open: {},
    height: {},
    width: {},
    title: {},
    headerClass: {},
    fixedHeight: {},
  },
  watch: {
    open: {
      handler(v) {
        if (process.browser) {
          if (v) {
            document.body.style.overflowY = "hidden";
            document.body.style.paddingRight = "8px";
          } else {
            document.body.style.overflowY = "unset";
            document.body.style.paddingRight = "0px";
          }
        }
      },
    },
  },
};
