export default (
  {
    req,
    res,
    route,
    $loading,
    app,
    store,
    $axios,
    $cookies,
    redirect,
    env,
    $clerk,
  },
  inject
) => {
  const list = (key, query, params) => {
    let l = {
      es_distinct: `${process.env.axiosUrl}/items/sv/distinct?store_id=${store.state.store.store_id}&${query}`,
      es_search: `${process.env.axiosUrl}/items/sv/store/${store.state.store.store_id}?store_id=${store.state.store.store_id}&fullProductPath=${store.state.store.eshop.fullProductPath}&catalogRoute=${store.state.store.eshop.catalogRoute}&${query}`,
      cms: `${process.env.axiosUrl}/cms/${params}?store_id=${store.state.store._id}${query}&clean=true`,
    };
    return l[key];
  };
  const anaxios = async (url, method, data, headers, er) => {
    try {
      let t = await $axios({
        url: url,
        method: method,
        data: data,
        headers: headers,
      });
      if (t.data) {
        return t.data;
      }
    } catch (error) {
      if (er) {
        er();
      }
    }
  };

  const getData = async ({ e, cookies, app, urlOnly }) => {
    return new Promise(async (resolve, reject) => {
      let url = ``;
      let p = "";
      let hardcoded = false;
      if (e.params[0].key == "hardcoded") {
        p = e.params[0].value;
        url = `${!p.includes("http") ? process.env.axiosUrl : ""}${p}`;
        // if (!url.includes("limit")) {
        //   url += `&limit=${e.limit}`;
        // }
        // if (!url.includes("order")) {
        //   url += `&${e.sorting}`;
        // }
        hardcoded = true;
      } else {
        p = e.params.reduce((acc, pa) => {
          if (pa.value || pa.key == "favorites") {
            pa.value = (pa.value + "").replace(/ ή /g, "_or_");
            let key = pa.key;
            if (key == "person") {
              key = "persons";
            } else if (key == "tag_key" && pa.tag_value) {
              return acc + `&${encodeURIComponent(key)}=${encodeURIComponent(pa.value)}&tag_value=${encodeURIComponent(pa.tag_value)}`;
            } else if (key == "favorites") {
              return (
                acc +
                `&barcodes=${((store.state.user || {}).favorites || []).join(
                  ","
                )}`
              );
            }
            return acc + `&${encodeURIComponent(key)}=${encodeURIComponent(pa.value)}`;
          } else {
            return acc + "";
          }
        }, "");
      }

      if (!p) {
        resolve(e);
        return;
      }
      e.limit = (e.limit || 16) > 50 ? 16 : e.limit || 16;
      let fields = ``;
      if (e.fields) {
        fields = "&fields=" + encodeURIComponent(e.fields.join(","));
        e.limit = e.limit || 100;
      }
      let unique = ``;
      if (e.unique) {
        unique = `&unique=true`;
      }

      try {
        if (!hardcoded) {
          url = `${process.env.axiosUrl}/items${
            app.$hyperUtility.safeIncludes(
              (store.state.store.eshop||{}).search,
              "elastic"
            )
              ? "/sv"
              : ""
          }/store/${store.state.store._id}?store_id=${
            store.state.store._id
          }${(p)}&accurate=1&limit=${e.limit}&${
            e.sorting || "&orderby=display_order&order=desc"
          }&exclude=comment${fields}${unique}`;
        }
        if (urlOnly) {
          return resolve({ url, method: "get", uid: e.uid, proc: "getData" });
        }
        let temp = await $axios.get(url, {
          headers: { Authorization: `Bearer ${cookies.get("token")}` },
        });
        let row = temp.data;
        if (e.fields && row.items.docs && row.items.docs[0]) {
          e.repeatData = row.items.docs;
        }
        if (row.items.docs && row.items.docs[0] && !e.fields) {
          if ((e.params.filter((f) => f.key == "barcodes") || []).length) {
            let barcodes = (
              (e.params.filter((f) => f.key == "barcodes") || [])[0] || {}
            ).value;
            row.items.docs = row.items.docs.sort((a, b) => {
              return barcodes.indexOf(a.barcode) - barcodes.indexOf(b.barcode);
            });
          }
          e.data = await app.$hyperItem.prepareItems(row.items.docs);
          e.data = row.items.docs;
        } else {
          e.data = [];
        }
      } catch (error) {
        console.error(error);
      }
      return resolve(e);
    });
  };

  const getBlogData = async ({ e, urlOnly }) => {
    return new Promise(async (resolve, reject) => {
      let p = (e.params || []).reduce((acc, pa) => {
        if (pa.value) {
          pa.value = pa.value.replace(/ ή /g, "_or_");
          let key = pa.key;
          return acc + `&${key}=${pa.value}`;
        } else {
          return acc + "";
        }
      }, "");
      if (!p) {
        resolve(e);
      }
      p = encodeURIComponent(p).replace(/%3D/g, "=").replace(/%26/g, "&");
      e.limit = (e.limit || 16) > 50 ? 16 : e.limit || 16;
      let url = `${process.env.axiosUrl}/blog?store_id=${
        store.state.store._id
      }${p}&accurate=1&limit=${e.limit}&${
        e.sorting || "&orderby=created_at&order=desc"
      }`;
      if (urlOnly) {
        resolve({ url, method: "get", uid: e.uid, proc: "getBlogData" });
      } else {
        try {
          let t = await $axios.$get(url);
          e.repeatData = t.docs;
        } catch (error) {
          console.error(error);
        }
        resolve(e);
      }
    });
  };
  const upload = async (f) => {
    let url = `${process.env.axiosUrl}/uploads/upload-public?store_id=${store.state.store._id}`;
    let fd = new FormData();
    fd.append("path", process.env.STATIC_ROUTE);
    fd.append("title", `${f.name}`);
    fd.append("store_id", store.state.store._id);
    fd.append("user_id", null);
    fd.append("permission", "public");
    fd.append("binFile", f);
    let up = await $axios.$post(url, fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return up
  };
  inject("hyperRemote", {
    anaxios,
    list,
    getData,
    getBlogData,
    upload
  });
};
