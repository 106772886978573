const initFacebook = function (ids, router) {
  var script = document.createElement("script");
  script.innerHTML = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
`;
  document.body.appendChild(script);
  var noscript = document.createElement("noscript");
  noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${ids}&ev=PageView&noscript=1"/>`;
  document.body.appendChild(noscript);
  ids.split(",").forEach((id) => {
    fbq("init", id);
  });
  try {
    fbq("track", "PageView");
  } catch (error) {}
  if (process.browser) {
    router.afterEach((to, from) => {
      try {
        fbq("track", "PageView");
      } catch (error) {}
    });
  }
};

const initGoogle = function (id, router) {
  //split id by comma
  // GTM-WZW4CQJ
  // id.split(',').forEach(i => {
  //   if (i.includes('GTM')) {
  //     var script = document.createElement("script");
  //     script.setAttribute('async', "true");
  //     script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${i}`);
  //     document.head.appendChild(script);
  //     var script = document.createElement("script");
  //     script.innerHTML = `window.dataLayer = window.dataLayer || [];
  //   function gtag(){dataLayer.push(arguments);}
  //   `;
  //     document.head.appendChild(script);
  //     script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  // new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  // j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  // 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  // })(window,document,'script','dataLayer','${i} ');`
  //     document.head.appendChild(script);
  //     var noscript = document.createElement('noscript');
  //     noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id} "
  //   height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  //     document.head.appendChild(noscript);
  //     gtag('js', new Date());
  //     gtag('config', id);
  //   } else {
  //     var script = document.createElement("script");
  //     script.innerHTML = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  //   (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  //   m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  //   })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  //   `;
  //     document.body.appendChild(script);
  //     ga('create', id, 'auto');
  //     ga('require', 'ec');
  //     if (process.browser) {
  //       router.afterEach((to, from) => {
  //         try {
  //           ga('set', 'page', to.fullPath);
  //           ga('send', 'pageview');
  //         } catch (error) {
  //         }
  //       });
  //     }
  //   }
  // })
};
const prepareItem = (item, index = 0) => {
  let obj = {
    item_id: item.sku || item.barcode,
    item_name: item.description,
    price: (item.prices || {}).price,
    item_brand: item.manufacturer,
    item_category: item.business_activity,
    item_list_name: item.business_activity,
    item_variant: item.variant,
    position: index,
    quantity: item.quantity,
    index,
  };
  if (item.family) {
    obj.item_category2 = item.family;
    obj.item_list_name = item.family;
  }
  if (item.subfamily) {
    obj.item_category3 = item.subfamily;
    obj.item_list_name = item.subfamily;
  }
  if (item.category) {
    obj.item_category4 = item.category;
    obj.item_list_name = item.category;
  }
  return obj;
};
const HyperGoogleAnalytics = {
  checkout: (cart) => {
    let dataLayer = [];
    dataLayer.push({
      ecommerce: null,
    }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            option: cart.payment.type,
          },
          products: cart.items.map((item) => prepareItem(item)),
        },
      },
    });
  },
  addProduct: (item, event) => {
    try {
      let dataLayer = [];
      dataLayer.push({
        ecommerce: null,
      });
      //event: productClick,addToCart,removeFromCart
      let analyticsDo = "click";
      if (event == "productClick") {
        analyticsDo = "click";
      } else if (event == "add_to_cart") {
        analyticsDo = "add";
      } else if (event == "removeFromCart") {
        analyticsDo = "remove";
      } else if (event == "add_to_wishlist") {
        analyticsDo = "remove";
      }
      dataLayer.push({
        event: "productClick",
        ecommerce: {
          currencyCode: "EUR",
          [analyticsDo]: {
            products: [
              {
                name: item.description, // Name or ID is required.
                id: item.barcode,
                price: item.prices.price,
                brand: item.manufacturer,
                category: `${item.business_activity} ${item.family} ${item.subfamily} ${item.category}`,
                variant: item.variant,
                position: item.position,
              },
            ],
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  },
};

const initTikTok = function (id) {
  var script = document.createElement("script");
  script.innerHTML = `!function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
    ttq.load('${id}} ');
    ttq.page();
  }(window, document, 'ttq');`;
  document.head.appendChild(script);
};

const initBestPrice = function (id, router) {
  var script = `<script> (function (a, b, c, d, s) {a.__bp360 = c;a[c] = a[c] || function (){(a[c].q = a[c].q || []).push(arguments);}; s = b.createElement('script'); s.async = true; s.src = d; (b.body || b.head).appendChild(s);}) (window, document, 'bp', '//360.bestprice.gr/360.js'); bp('connect', '${id}'); </script> `;

  let node = document.createElement("script");
  node.innerHTML = script;
  document.body.appendChild(node);
};
const initSkroutz = function (id, router) {
  window.sa_plugins = "";
  window.SkroutzAnalyticsLoader = "";
  var script = `(function(a,b,c,d,e,f,g){a['SkroutzAnalyticsObject']=e;a[e]= a[e] || function(){
          (a[e].q = a[e].q || []).push(arguments);};f=b.createElement(c);f.async=true;
          f.src=d;g=b.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g);
        })(window,document,'script','https://analytics.skroutz.gr/analytics.min.js','skroutz_analytics');
        `;
  let node = document.createElement("script");
  node.innerHTML = script;
  document.body.appendChild(node);
  skroutz_analytics("session", "connect", id);
  if (process.browser) {
    router.afterEach((to, from) => {
      try {
        window.sa_plugins = "";
        window.SkroutzAnalyticsLoader = "";
        var script = `(function(a,b,c,d,e,f,g){a['SkroutzAnalyticsObject']=e;a[e]= a[e] || function(){
          (a[e].q = a[e].q || []).push(arguments);};f=b.createElement(c);f.async=true;
          f.src=d;g=b.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g);
        })(window,document,'script','https://analytics.skroutz.gr/analytics.min.js','skroutz_analytics');
        `;
        let node = document.createElement("script");
        node.innerHTML = script;
        document.body.appendChild(node);
        skroutz_analytics("session", "connect", id);
      } catch (error) {
        console.error(error);
      }
    });
  }
};

import { setOptions, bootstrap } from "vue-gtag";

export default ({ app, store, route }, inject) => {
  const informant = ($nuxt) => {
    let dataLayer = [];
    app.router.afterEach((to, from) => {
      if (to.name == "item-id") {
        $nuxt.$emit("informant:itemView", $nuxt.$store.getters.item);
      }
    });
    if (process.browser) {
      if(!window.dataLayer){
        window.dataLayer = [];
      }
      if (
        (store.getters.store.flat_plugins["Google Analytics"] || {}).google_id
      ) {
        let ids = (
          store.getters.store.flat_plugins["Google Analytics"] || {}
        ).google_id.split(",");
        setOptions({
          config: {
            id: store.getters.store.flat_plugins["Google Analytics"].google_id,
          },
          includes: ids.map((id) => ({ id })),
        });
        
      }
      if ((store.getters.store.flat_plugins["GTM"] || {}).id) {
        app.$gtm.init(store.getters.store.flat_plugins["GTM"].id);
        app.$gtm.push({event:'consentGranted'});
      }
      if (
        (store.getters.store.flat_plugins["Google Analytics"] || {}).google_id
      ) {
        app.$gtm.init(
          store.getters.store.flat_plugins["Google Analytics"].google_id
        );
        app.$gtm.push({event:'consentGranted'});
      }
      $nuxt.$on("informant:itemView", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        if (!(v || {}).prices) {
          return;
        }
        app.$gtm.push(
          "productView",
          HyperGoogleAnalytics.addProduct(v, "productClick")
        );
      });
      $nuxt.$on("informant:removeItem", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "remove_from_cart",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(
            ((v.item || {}).prices || {}).price,
            2
          ),
          items: [prepareItem(v.item, v.index || v.item.index)],
        });
      });
      $nuxt.$on("informant:addToCart", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "add_to_cart",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(
            ((v.item || {}).prices || {}).price,
            2
          ),
          items: [prepareItem(v.item, v.index || v.item.index)],
          contents:[{id:v.item.sku||v.item.barcode,quantity:v.item.quantity}]
        });
      });
      $nuxt.$on("informant:removeFromCart", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "remove_from_cart",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(
            ((v.item || {}).prices || {}).price,
            2
          ),
          items: [prepareItem(v.item, v.index || v.item.index)],
        });
      });
      $nuxt.$on("informant:addToWishlist", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "add_to_wishlist",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(
            ((v.item || {}).prices || {}).price,
            2
          ),
          items: [prepareItem(v.item, v.index || v.item.index)],
        });
      });
      $nuxt.$on("informant:view_item_list", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "view_item_list",
          item_list_name: v.list_name,
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
        });
      });
      $nuxt.$on("informant:select_item", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "select_item",
          item_list_id: v.id,
          item: prepareItem(v.item, v.index),
        });
      });
      $nuxt.$on("informant:view_item", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "view_item",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(
            ((v.item || {}).prices || {}).price,
            2
          ),
          items: [prepareItem(v.item)],
        });
      });
      $nuxt.$on("informant:view_cart", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "view_cart",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(v.sum, 2),
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
        });
      });
      $nuxt.$on("informant:begin_checkout", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "begin_checkout",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(v.sum, 2),
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
        });
      });
      $nuxt.$on("informant:add_shipping_info", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "add_shipping_info",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(v.sum, 2),
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
          shipping_tier: v.shipping.title,
        });
      });
      $nuxt.$on("informant:add_payment_info", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "add_payment_info",
          currency: "EUR",
          value: app.$hyperUtility.toSafeFixed(v.sum, 2),
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
          shipping_tier: v.shipping.title,
        });
      });
      $nuxt.$on("informant:purchase", (v) => {
        try{
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        //phone order
        app.$gtm.push({
          event: "purchase",
          transaction_id: v.fid||v.order_id,
          value: app.$hyperUtility.toSafeFixed(v.sum, 2),
          tax: v.vat,
          shipping: v.shipping.cost,
          email: (v.user || {}).email,
          phone: (v.user || {}).phone,
          currency: "EUR",
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
          phone_order: !!(v.operator || {}).id
        });
      }catch(error){
        $nuxt.$clerk.sendMail(
          "alex",
          "",
          error,
          "alexkarpen@gmail.com",
          "analytics",
          "alexkarpen@gmail.com",
          true
        );
      }
      });
      $nuxt.$on("informant:view_promotion", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({
          event: "view_promotion",
          promotion_id: v.code,
          promotion_name: v.name,
          items: (v.items || []).map((i, index) => prepareItem(i, index)),
        });
      });
      $nuxt.$on("informant:newsletter", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        // app.$gtm.push({
        //   event: "view_promotion",
        //   promotion_id: v.code,
        //   promotion_name: v.name,
        //   items: (v.items || []).map((i,index) => prepareItem(i,index)),
        // });
      });
      $nuxt.$on("informant:register", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({
          event: "signup",
          method: v.method,
          user_id: v._id,
        });
      });
      $nuxt.$on("informant:login", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({
          event: "login",
          method: v.method,
          user_id: v._id,
        });
      });
      $nuxt.$on("informant:search", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        app.$gtm.push({
          event: "search",
          search_term: v.q,
        });
      });
      $nuxt.$on("informant:element", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        
        app.$gtm.push({
          event: v.event,
          payload:v.payload,
        });
      });
      $nuxt.$on("informant:pageView", (v) => {
        if (process.browser) {
          window.dataLayer.push(function () {
            this.reset();
          });
        }
        let layer = {
          ecomm_pagetype: undefined,
          ecomm_pcat: undefined,
          ecomm_prodid: undefined,
          ecomm_pname: undefined,
          ecomm_pvalue: undefined,
          ecomm_totalvalue: undefined,
        };
        if (process.browser) {
          route = $nuxt.$route;
        }
        if (route.name == "index") {
          layer = { ecomm_pagetype: "home" };
        } else if (
          route.name == "catalog-full" &&
          !route.path.includes("/product/")
        ) {
          layer = {
            ecomm_pagetype: "category",
            ecomm_pcat: [(store.getters.filters || {}).business_activity],
          };
        } else if (route.name == "catalog-full") {
          layer = {
            ecomm_pagetype: "product", //set the value
            ecomm_pcat: store.getters.item.business_activity, // product category
            ecomm_prodid: store.getters.item.sku, // sku
            ecomm_pname: store.getters.item.description, // product name
            ecomm_pvalue: app.$hyperUtility.toSafeFixed(
              (store.getters.item.prices || {}).price,
              2
            ), // individual product values
            contents: [{id:store.getters.item.sku||store.getters.item.barcode,quantity:store.getters.item.quantity}],
          };
        } else if (route.name == "cart-step") {
          layer = {
            ecomm_pagetype: "cart",
            ecomm_prodid: (store.getters.cart.items || []).map((i) => i.sku), // id (sku)
            ecomm_pname: (store.getters.cart.items || []).map(
              (i) => i.description
            ), // product name
            ecomm_pcat: (store.getters.cart.items || []).map(
              (i) => i.business_activity
            ), // category
            ecomm_pvalue: app.$hyperUtility.toSafeFixed(
              (store.getters.cart.items || []).map(
                (i) => (i.prices || {}).price
              ),
              2
            ), // individual product values
            ecomm_totalvalue: store.getters.cart.sum, // total cart value};
            contents: (store.getters.cart.items || []).map(
              (i) => ({id:i.sku||i.barcode,quantity:i.quantity})
            )
          };
        } else if (route.name == "success") {
          layer = {
            ecomm_pagetype: "purchase", //set the value
            ecomm_pcat: ((store.getters.afterCart || {}).items || []).map(
              (i) => i.business_activity
            ), // product category
            ecomm_prodid: ((store.getters.afterCart || {}).items || []).map(
              (i) => i.sku
            ), // sku
            ecomm_pname: ((store.getters.afterCart || {}).items || []).map(
              (i) => i.description
            ), // product name
            ecomm_pvalue: app.$hyperUtility.toSafeFixed(
              ((store.getters.afterCart || {}).items || []).map(
                (i) => (i.prices || {}).price
              ),
              2
            ), // product value
            ecomm_totalvalue: (store.getters.afterCart || {}).sum, // total value // total cart value};
            contents: ((store.getters.afterCart||{}).items || []).map(
              (i) => ({id:i.sku||i.barcode,quantity:i.quantity})
            )
          };
        }
        layer = {
          google_tag_params: layer,
        };
        app.$gtm.push({ ecommerce: null });
        app.$gtm.push({ event: "pageView", ...layer });
      });
    }
  };

  const optOut = () => {
    let events = Object.keys($nuxt._events).filter((e) =>e.includes("informant:"));
    events.forEach((e) => {
      $nuxt.$off(e);
    });
  }
  // Inject $hello(msg) in Vue, context and store.
  inject("analytics", {
    initFacebook,
    initGoogle,
    HyperGoogleAnalytics,
    initSkroutz,
    initBestPrice,
    initTikTok,
    informant,
    optOut
  });
};
