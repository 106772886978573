
export default {
  data() {
    return {
      open: false,
      item: {},
      promos: [],
      panel_type: "",
    };
  },
  mounted() {
    this.$nuxt.$on("pick_gift", ({ item, promos, open }) => {
      if (item) {
        this.item = this.$store.getters.cart.items.find(
          (i) => i.barcode == item.barcode
        );
        this.promos = promos.map((p) => {
          p.gifts = (p.gifts || []).map((g) => {
            g.selected = (this.item.gifts || []).some(
              (gg) => gg.barcode == g.barcode
            );
            return g;
          });
          return p;
        }).sort((a,b)=>b.title.length-a.title.length);
      } else {
        this.promos = promos.map((p) => {
          p.gifts = (p.pick_from || []).map((g) => {
            g.selected = this.$store.getters.cart.items.some(
              (i) => i.isGift && i.barcode == g.barcode && i.ruled == p.uid
            );
            return g;
          });
          return p;
        }).sort((a,b)=>b.title.length-a.title.length);
      }
      this.panel_type_decide();
      this.open = open;
    });
  },
  methods: {
    panel_type_decide() {
      if (
        (this.promos || []).length == 1 &&
        (((this.promos || [])[0] || {}).gifts || []).length == 1
      ) {
        this.panel_type = "single";
      } else if (
        (this.promos || []).length == 1 &&
        (((this.promos || [])[0] || {}).gifts || []).length > 1
      ) {
        this.panel_type = "gifts";
      } else if (
        (this.promos || []).length > 1 &&
        (this.promos || []).every((p) => (p.gifts || []).length == 1)
      ) {
        this.panel_type = "promos";
      } else if ((this.promos || []).some((p) => (p.gifts || []).length)) {
        this.panel_type = "static";
      } else {
        this.panel_type = "mixed";
      }
    },
    select(p, g) {
      let cart = this.$hyperCart.getCart();
      if(g.selected){
        cart.items = cart.items.filter((i) => i.ruled != g.ruled || i.barcode != g.barcode);
        this.$hyperCart.saveCart(cart);
        return;
      }
      if(p.maximum){
        let selectedCount = cart.items.filter((i) => i.ruled == p.uid)?.length || 1;
        if(selectedCount >= p.maximum){
          let ii = cart.items.findIndex(i=>i.ruled==p.uid)
          if(ii>0){
            cart.items.splice(ii,1);
          }
        }
      }
      if (!this.item.barcode) {
        p.gifts = p.gifts.map((gg) => {
          if (gg.barcode == g.barcode && gg.ruled == g.ruled) {
            gg.selected = true;
          }else{
            gg.selected = false;
          }
          return gg;
        });
        
          cart.items.push({
            ...g,
            isGift: true,
            quantity: 1,
            gifts: [],
          });
        
      } else {
        for (const i of cart.items) {
          if (i.barcode == this.item.barcode) {
            if (!i.gifts) {
              i.gifts = [];
            }
            if (!i.gifts) {
              i.gifts = [];
            }
            let giftsQ = i.gifts.reduce((a, b) => a + b.quantity || 1, 0);
            i.giftsMaximum = p.maximum;
            if (p.maximum > i.quantity) {
              p.maximum = i.quantity;
            }
            if (giftsQ >= p.maximum) {
              p.gifts = p.gifts.map((g) => {
                if (i.gifts[0].barcode == g.barcode) {
                  g.selected = false;
                }
                return g;
              });
              i.gifts = i.gifts.slice(1);
            }
            g.selected = true;
            i.gifts.push(g);
          }
        }
      }
      this.$hyperCart.saveCart(cart);
      this.$forceUpdate();
    },
  },
};
