import { Swiper, Navigation, Pagination, EffectCards, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";

const emit = (vnode, name, data) => {
  let handlers =
    (vnode.data && vnode.data.on) ||
    (vnode.componentOptions && vnode.componentOptions.listeners);

  if (handlers && handlers[name]) {
    handlers[name].fns(data);
  }
};
const getSwiperInstanceName = (element, binding, vnode) => {
  return binding.arg || element.id || element.uid;
};
const getSwiperOptions = (element, binding, vnode) => {
  let options = {
    // @see https://swiperjs.com/swiper-api#parameters
    loop: false,
    speed: element.noSlider ? 0 : 300,
    grabCursor: !element.noDrag && !element.noSlider,
    allowTouchMove: !element.noDrag && !element.noSlider,
    slidesPerView: element.slidesPerView || "auto",
    spaceBetween: element.spaceBetween || 0,
    modules: [Navigation, Pagination, Autoplay],
    pagination: !element.dots
      ? {}
      : {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
    navigation: !element.arrows
      ? {}
      : {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
  };
  if (element.autoHeight) {
    options.autoHeight = true;
  }
  if (element.width) {
    // options.width = element.width;
  }
  if (element.height) {
    options.height = element.height;
  }
  if (element.nested) {
    options.nested = element.nested;
  }
  if (element.threshold) {
    options.threshold = element.threshold;
  }
  if (element.autoplay) {
    options.autoplay = {
      pauseOnMouseEnter: true,
      delay: 7000,
    };
  }
  if (element.shortSwipes) {
    options.shortSwipes = !element.shortSwipes;
    options.longSwipesRatio = 0.1;
    options.resistanceRatio = 0.1;
  }
  if (element.grid) {
    options.grid = {
      rows: element.grid || 2,
    };
  }
  return options;
};
export default {
  bind(el, binding, vnode) {
    let element = {};
    if (typeof (binding.value || {}).element == "string") {
      try {
        binding.value.element = JSON.parse(binding.value.element);
      } catch (e) {
        console.error(e);
      }
    }
    if ((binding.value || {}).element) {
      element = binding.value.element;
    } else {
      element = binding.value;
    }
    let wrapper = el;
    if (vnode.context.$store.getters.isMobile) {
      let keys = [
        "grid",
        "arrows",
        "dots",
        "noDrag",
        "autoplay",
        "slidesPerView",
        "threshold",
        "spaceBetween",
        "slidesOffsetAfter",
        "slidesOffsetBefore",
        "nested",
        "shortSwipes",
        "paginationStyles",
        "navigationStyles",
        "wrapper",
        "noSlider",
        "width",
        "height",
        "padding",
      ];
      keys.forEach((key) => {
        key = key + "Mobile";
        if (key.includes("Mobile") && element[key]) {
          element[key.replace("Mobile", "")] = element[key];
        } else {
          element[key.replace("Mobile", "")] = false;
        }
      });
    }
    if (element.wrapper) {
      wrapper = el.parentNode;
    }
    // configure Swiper to use modules. The modules were tested with SwiperJS v6.8.4 with NuxtJS v2.15.7
    // previously it was before export default. Moved here for performance issues. Move back in case of problems.
    // init Swiper:  eslint-disable used for deleting error of unsued const swiper
    /* eslint-disable no-unused-vars */
    el.classList.add("swiper");
    el.classList.add("scrollbar-hidden");
    el.classList.add("w-full");
    if (element.grid || element.gridMobile) {
      el.classList.add("h-full");
    }
    if (element.padding) {
      let slides = el.querySelectorAll(".swiper-slide");
      slides.forEach((slide) => {
        slide.style.padding = `0 ${element.padding}px`;
      });
    }

    vnode.context.$nextTick(() => {
      if (element.dots) {
        wrapper.insertAdjacentHTML(
          "afterbegin",
          `<div style="${element.paginationStyles}" class="swiper-pagination brand-slider-pagination ${element.paginationClasses}" slot="pagination" ></div>`
        );
      }
      if (element.arrows) {
        wrapper.insertAdjacentHTML(
          "afterbegin",
          `<div style="${element.dotsStyle}" class=" ${element.dotsClasses} swiper-button-prev brand-slider-button-prev h-8 w-8 rounded-hyperl flex items-center justify-center" slot="button-prev" > <img class="rotate-180 h-full w-full object-contain" data-icon="custom" src="${vnode.context.$store.getters.store.eshop.icons.arrow}" alt="icon_arrow" /> </div>
          <div style="${element.dotsStyle}" class=" ${element.dotsClasses} swiper-button-next brand-slider-button-next h-8 w-8 rounded-hyperl flex items-center justify-center" slot="button-next" > <img class=" h-full w-full object-contain" data-icon="custom" src="${vnode.context.$store.getters.store.eshop.icons.arrow}" alt="icon_arrow" /> </div>`
        );
      }
    });

    if (element.slidesPerView) {
      el.classList.add("!pr-8");
    }
    setTimeout(
      (binding) => {
        const vueContext = vnode.context;
        const instanceName = getSwiperInstanceName(element, binding, vnode);
        const swiper = new Swiper(
          el,
          getSwiperOptions(element, binding, vnode)
        );
        vueContext[instanceName] = swiper;
        element.swiper = swiper;
        swiper.on("activeIndexChange", (swiper) => {
          emit(vnode, "activeIndexChange", swiper.activeIndex);
        });

        if (element.activeSlide > 1) {
        }
        if (binding.value.update) {
          if (element.shopster_id) {
            $nuxt.$on(`slider${element.shopster_id}_current`, (index) => {
              setTimeout(() => {
                $nuxt.$emit(binding.value.update, swiper.activeIndex + 1);
              }, 2);
            });
          }
          swiper.on("activeIndexChange", (swiper) => {
            $nuxt.$emit(binding.value.update, swiper.activeIndex + 1);
          });
        }
      },
      150,
      binding
    );
  },
};
