import { render, staticRenderFns } from "./internal.vue?vue&type=template&id=9013b166"
import script from "./internal.vue?vue&type=script&lang=js"
export * from "./internal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Preview: require('/root/containers/hermes_eshop/components/core/preview.vue').default,ScssInit: require('/root/containers/hermes_eshop/components/core/scssInit.vue').default,DesignSystem: require('/root/containers/hermes_eshop/components/core/designSystem.vue').default,Cookies: require('/root/containers/hermes_eshop/components/core/cookies.vue').default,Specific: require('/root/containers/hermes_eshop/components/core/specific.vue').default})
