
export default {
  name: "scssInit",
  data() {
    return {
      styles: {},
      stylesHover: {},
      stylesMobile: {},
      stylesMobileHover: {},
      field: (this.$store.getters.store.eshop.coreElements || {}).field || {},
    };
  },
  mounted() {},
  methods: {
   
    transparit(c) {
      if (!c) {
        c = "";
      }
      if (c.includes("#")) {
        if (c.length == 9) {
          c = c.slice(0, 7);
        }
      }
      return c;
    },
    styleToCss(pseudo = "", b){
      let style = this.reactiveStyle(pseudo,b)
      return Object.keys(style || {}).reduce((acc, _) => {
          if (style[_] != undefined) {
            acc += `${(_||'').replace(/[A-Z]/g, letter => `-${(letter+'').toLowerCase()}`)}:${style[_]};`;
          }
          return acc;
        }, "")
    },
    reactiveStyle(pseudo = "", b) {
      if(!b.style){
        return {};
      }
      let actualValue = (key, pseudo = "", url) => {
        let value = b.style[`${key}${pseudo}`];

        if (url && value) {
          return `url(${value})`;
        }
        let excludes = [
          "flexGrow",
          "flexShrink",
          "zIndex",
          "order",
          "fontWeight",
          "backdropFilter",
        ];
        if (value === undefined) {
          return undefined;
        }
        if (excludes.includes(key)) {
          return value;
        }
        return this.percentage(value);
      };
      let hideIt = (pseudo) => {
        if (b.style[`hide${pseudo}`]) {
          return `none`;
        }
        return undefined;
      };
      if (!b.style["hideMobile"]) {
        b.style["displayMobile"] = "flex";
      }
      if (b.style["hide"]) {
        b.style["display"] = "none";
      }
      let urls = ['backgroundImage']
      return Object.keys(b.style).reduce((acc, k) => {
        if (b.style[k]) {
          acc[k] = actualValue(k, pseudo, urls.includes(k))
        }
        return acc;
      },{})
    },
    percentage(v, skip) {
      v = v + "";
      if ((v == "auto" || v == "") && !skip) {
        return "auto";
      }
      return isNaN(v) ? v : v + "px";
    },
  },
  computed: {
    
  },
};
