export default [{
  type: 'style',
  key: 'width',
  actual: 'width'
}, {
  type: 'style',
  key: 'height',
  actual: 'height'
}, {
  type: 'style',
  key: 'widthMobile',
  actual: 'widthMobile'
}, {
  type: 'style',
  key: 'heightMobile',
  actual: 'heightMobile'
}, {
  type: 'style',
  key: 'color',
  actual: 'backgroundColor'
}, {
  type: 'style',
  key: 'colorText',
  actual: 'color'
}, {
  type: 'style',
  key: 'border',
  actual: ''
}, {
  type: 'style',
  key: 'colorShadow',
  actual: ''
}, {
  type: 'style',
  key: 'spreadShadow',
  actual: ''
}, {
  type: 'style',
  key: 'blurShadow',
  actual: ''
}, {
  type: 'style',
  key: 'rounded',
  actual: 'borderRadius'
}, {
  type: 'style',
  key: 'margin',
  actual: 'margin'
}, {
  type: 'style',
  key: 'padding',
  actual: 'padding'
}, {
  type: 'style',
  key: 'floatingTop',
  actual: 'top'
}, {
  type: 'style',
  key: 'floatingRight',
  actual: 'right'
}, {
  type: 'style',
  key: 'floatingBottom',
  actual: 'bottom'
}, {
  type: 'style',
  key: 'floatingLeft',
  actual: 'left'
}, {
  type: 'style',
  key: 'floatingPosition',
  actual: 'position'
}, {
  type: 'style',
  key: 'floatingZ',
  actual: 'zIndex'
}, {
  type: 'data',
  key: 'scroll',
  actual: 'scrollto'
}, {
  type: 'class',
  key: 'mobileHide',
  actual: 'hidden'
}, {
  type: 'class',
  key: 'desktopHide',
  actual: 'lg:hidden'
}, {
  type: 'style',
  key: 'flexOrder',
  actual: 'order'
}, {
  type: 'style',
  key: 'flexOrderMobile',
  actual: 'order'
}, {
  type: 'style',
  key: 'overflowΧ',
  actual: 'overflowΧ'
}, {
  type: 'style',
  key: 'overflowΥ',
  actual: 'overflowΥ'
},{
    type: 'style',
    key: 'transform',
    actual: 'transform'
  },{
    type: 'style',
    key: 'filter',
    actual: 'filter'
  },{
    type: 'style',
    key: 'backdrop-filter',
    actual: 'backdrop-filter'
  }, {
  type: 'attr',
  key: 'href',
  actual: ''
}, {
  type: 'inner',
  key: 'label',
  actual: '',
  selector: '.element-label'
}, {
  type: 'style',
  key: 'icon',
  actual: '',
  selector: '.element-icon'
}, {
  type: 'style',
  key: 'iconWidth',
  actual: 'width',
  selector: '.element-icon'
}, {
  type: 'style',
  key: 'iconHeight',
  actual: 'height',
  selector: '.element-icon'
}, {
  type: 'attr',
  key: 'target',
  actual: 'target'
}, {
  type: 'style',
  key: 'direction',
  actual: 'flexDirection'
}]
