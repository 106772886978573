
export default {
  name: "NuxtError",
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    // this.$hyperUtility.paintSvg("outOfReach", "#error500", "#B84D4F");
    // this.$hyperUtility.paintSvg("404", "#error404", "#455a64");
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    message() {
      return this.error.message || "<%= messages.client_error %>";
    },
  },
};
