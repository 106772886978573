import Vue from "vue";
import { mixin as clickaway } from "vue-clickaway";
import VueGtag from "vue-gtag";
import VueLazyload from 'vue-lazyload'

export default ({ app, store, route }, inject) => {
  Vue.use(
    VueGtag,
    {
      config: {
        id: "",
      },
      pageTrackerTemplate(to) {
        return {
          page_title: document.title,
          page_path: to.path,
        };
      },
      enabled: true,
      bootstrap: false,
    },
    app.router
  );
  let lazyLoad = ((store.getters.store.eshop||{}).icons||{}).lazyLoad
  let lazyNoPhoto = ((store.getters.store.eshop||{}).icons||{}).lazyNoPhoto
  
  Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: lazyNoPhoto||'/lazy.svg',
    loading: lazyLoad||'/lazy.svg',
    attempt: 1,
    observer: true,
  })
};

Vue.mixin({
  mixins: [clickaway],
});