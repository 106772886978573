
export default {
data() {
    return {
      elements: this.$store.getters.store.eshop.elements.filter(
        (e) =>
          e.page == this.$store.getters.store.eshop.layout.header ||
          e.page == "header"
      ),
    };
  },
}
