
export default {
  props: ["element","fixed"],
  computed: {
    searchComputed() {
      if (!this.$store.getters.store.eshop) {
        return "";
      }
      if(this.$store.getters.actual.search){
        return this.$store.getters.actual.search;
      }
      if (this.$store.getters.store.eshop.search == "elasticSearchBar") {
        return () => import(`./searchBar.vue`);
      }
      if (this.$store.getters.store.eshop.search == "elastic") {
        return () => import(`./searchSuggestions.vue`);
      }
      return () => import(`./searchDefault.vue`);
    },
  },
};
