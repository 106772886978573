
export default {
  data() {
    return {
      focus: false,
      open: false,
      accountOpen: false,
      tab: !this.$store.getters.isMobile ? "register" : false,
      pendingOrder: {},
    };
  },
  mounted() {
    this.pendingOrder = this.$clerk.getUserOrders(
      this.$store,
      "pending",
      false,
      true
    )||{};
    this.$nuxt.$on("closeMenu", (_) => {
      this.open = false;
    });
    this.$nuxt.$on("openAuth", (_) => {
      this.accountOpen = true;
    });
  },
  methods: {
    away() {
      this.open = false;
    },
  },
};
