
export default {
  props: ["elements"],
  data() {
    return {
      newsletterAgree: false,
      newsLetterEmail: "",
      categoryOpen: false,
      items: {
        offers: [],
        latest: [],
        actual: [],
      },
      pagination: [],
      total: 0,
      total: 0,
      limit: 24,
      page: 1,
      pages: 1,
      search: "",
      articles: [],
      top10: [],
      bestSellers: [],
      fresh: [],
      offers: [],
    };
  },
  

};
