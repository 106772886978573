const safeIncludes=(v,q)=>{
  return ((v+'')||'').includes(q)
}
export default function ({
  app,
  route,
  redirect,
  store,
  res
}) {
  if (route.path.includes('/item/') && ((route.path || '').replace('/item/', '').match(/[\/&=? ]/g) || []).length) {
    redirect('/item/' + route.path.replace('/item/', '').replace(/[\/&=? ]/g, '-'));
  } else if (route.path.includes('/search/')) {
    if ((store.state.store.eshop.search||'').includes('elastic')) {
      let params = ((route.params.q || '').split('+') || []).filter(x => x).reduce((acc, rp) => {
        rp = rp.replace(/______/g, '+');
        acc[rp.split('=')[0]] = rp.split('=')[1]
        return acc;
      }, {})
      let queries = Object.keys(params).reduce((acc, p) => {
        if (!['business_activity', 'family', 'subfamily', 'category', 'tag_key', 'tag_value'].includes(p)) {
          acc[p] = params[p];
        }
        if (p == 'tag_key') {
          acc.tags = `${params.tag_value == 'person'?'persons':params.tag_value} | ${params.tag_key}`
        }
        if (p == 'person') {
          acc.persons = param[p];
        }
        return acc;
      }, {})
      let pars = {};
      let b = `/${store.getters.store.eshop.catalogRoute}`;
      if (params.category) {
        pars.business_activity = encodeURIComponent(params.business_activity || '-')
        pars.family = encodeURIComponent(params.family || '-')
        pars.subfamily = encodeURIComponent(params.subfamily || '-')
        pars.category = encodeURIComponent(params.category);
        b += `/${pars.business_activity}/${pars.family}/${pars.subfamily}/${pars.category}`
      }
      else if (params.subfamily) {
        pars.business_activity = encodeURIComponent(params.business_activity || '-')
        pars.family = encodeURIComponent(params.family || '-')
        pars.subfamily = encodeURIComponent(params.subfamily || '-')
        b += `/${pars.business_activity}/${pars.family}/${pars.subfamily}`
      }
      else if (params.family) {
        pars.business_activity = encodeURIComponent(params.business_activity || '-')
        pars.family = encodeURIComponent(params.family || '-')
        b += `/${pars.business_activity}/${pars.family}`
      }
      else if (params.business_activity) {
        pars.business_activity = encodeURIComponent(params.business_activity || '-')
        b += `/${pars.business_activity}`
      }
      if (params.manufacturer) {
        b += `/m.${encodeURIComponent(params.manufacturer)}`
      }
      b += `?${Object.keys(queries).reduce((acc,qu)=>{
        if(qu!='manufacturer'){
          acc+=`${encodeURIComponent(qu)}=${encodeURIComponent(queries[qu])}&`
        }
        return acc;
      },'')}`
      redirect(b);
    }
    let params = route.path.split('/search/')[1];
    if (params.includes('%2F') || params.includes('%252F') || params.includes('/')) {
      redirect('/search/' + params.replace(/%2F/g, '___').replace(/%252F/g, '___').replace(/\//g, '___'));
    }
  } else if (route.path.includes('/search')) {
    if (route.query.q) {
      if (app.$hyperUtility.safeIncludes(store.state.store.eshop.search,'elastic')) {
        redirect(`/${store.getters.store.eshop.catalogRoute}?q=${route.query.q}`)
      } else {
        redirect(`/search/+q=${route.query.q}`)
      }
    }
  }
}
