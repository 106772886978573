
export default {
  data() {
    return {
      open: false,
      elements: this.$store.getters.store.eshop.elements.filter(
        (e) => e.page == "addtocart"
      ) || [],
    };
  },
  watch: {
    "open": function (v) {
      if (v) {
        document.querySelector("body").classList.add("overflow-hidden");
      } else {
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    this.$nuxt.$on("addToCartPop", (v) => {
      this.open = true;
    });
  },
};
