
import hyperOptions from "./options";
export default {
  components: {
    hyperOptions,
  },
  data() {
    return {
      item: JSON.parse(JSON.stringify(this.$store.getters.optionsRequired)),
    };
  },
  methods: {
    setVariation(io, value, index) {
      if (!value) {
        delete io.selected;
      } else {
        io.selected = value;
      }
      let variations = this.item.variation;
      if (variations) {
        let found = -1;
        variations.forEach((v, vindex) => {
          if (v && v.key == io.key) {
            found = vindex;
          }
        });
        if (found == -1 && value) {
          variations.push({ ...(value || {}) });
        } else {
          if (!value) {
            delete variations[found];
          } else {
            variations[found] = value;
          }
        }
      } else if (value) {
        variations = [{ ...(value || {}) }];
      }
      if (variations && variations[0] == null) {
        delete this.item.variation;
      } else {
        this.item.variation = variations;
      }
      this.item.prices.actual_price = this.$hyperItem.actualPrice(this.item).toFixed(2);

      let required = [];
      this.item.options.forEach((o) => {
        if (o.required) {
          required = [...required, o.key];
        }
      });
      if (this.item.variation) {
        this.item.variation.forEach((v) => {
          if (required.includes(v.key)) {
            required.splice(required.indexOf(v.key), 1);
          }
        });
      }
      this.item.required = required;
      this.$store.commit("item", JSON.parse(JSON.stringify(this.item)));
      this.$forceUpdate();
    },
    addCart() {
      this.$hyperCart.addToCart(this.item, this.$hyperCart.getCart());
      $nuxt.$emit("updateCart", "updated");
      this.$store.commit('optionsRequired', {});
    },
  },
};
